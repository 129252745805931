import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ActivityPanel from "./ActivityPanel";
import InboxPanel from "./InboxPanel";
import NotificationsPanel from "./NotificationsPanel";
import { useDispatch } from "react-redux";
import { resetNotifications } from "../../../actions/dashboardAction";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		border: 0
	},
}));

export default function DashboardSidebar() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const dispatch = useDispatch();

	const handleChange = (event, newValue) => {
		if(newValue === 2) {
			dispatch(resetNotifications([]));
		}
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar className="sidebar__appBar" position="static">
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example">
					<Tab label="Activity" {...a11yProps(0)} />
					<Tab label="Inbox" {...a11yProps(1)} />
					<Tab label="Notifications" {...a11yProps(2)} />
				</Tabs>
			</AppBar>
			<TabPanel className="activity_tabPanel" value={value} index={0}>
				<ActivityPanel/>
			</TabPanel>
			<TabPanel className="inbox__tabPanel common__tabPanel" value={value} index={1}>
				<InboxPanel/>
			</TabPanel>
			<TabPanel className="notification__tabPanel common__tabPanel" value={value} index={2}>
				<NotificationsPanel/>
			</TabPanel>
		</div>
	);
}
