import { DATEPICKER_INITIAL_SETTING } from "../constants/CoreConstants";
import * as ACTION from "../constants/dashboardActionTypes";

const initialState = {
    dashboardCountLoading: true,
    rightListLoading: true,
    dealCount: { opened: 0, lost: 0, win: 0 },
    dealValue: { opened: 0, lost: 0, win: 0 },
    rate: 0,
    piePipelines: [],
    funnelPipelines: [],
    contactSourceLoading: true,
    contactSourceCount: [],
    selectedFunnelPipeline: '',
    selectedPiePipeline: '',
    funnelLoading: true,
    pieLoading: true,
    funnelData: [],
    pieData: [],
    userActivities: [],
    timelines: {},
    notifications: [],
    startDate: DATEPICKER_INITIAL_SETTING.startDate.format('YYYY-MM-DD'),
    endDate: DATEPICKER_INITIAL_SETTING.endDate.format('YYYY-MM-DD'),
    filterUser: true
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardCountLoading: false,
                dealCount: action.payload.count,
                dealValue: action.payload.value,
                rate: conversionRate(action.payload.rate)
            }
        case ACTION.STORE_DEAL_COUNT:
            return {
                ...state,
                dealCount: action.payload.count
            }
        case ACTION.STORE_DEAL_VALUE:
            return {
                ...state,
                dealValue: action.payload.value
            }
        case ACTION.STORE_DEAL_CONVERSION:
            return {
                ...state,
                rate: conversionRate(action.payload.rate)
            }
        case ACTION.STORE_PIPELINE_SOURCES:
            return {
                ...state,
                contactSourceLoading: false,
                piePipelines: action.payload.piePipelines,
                funnelPipelines: action.payload.funnelPipelines,
                contactSourceCount: action.payload.contactSourceCount,
                selectedFunnelPipeline: action.payload.selectedFunnelPipeline,
                selectedPiePipeline: action.payload.selectedPiePipeline,
            }
        case ACTION.STORE_FUNNEL_PIPELINE_ID:
            return {
                ...state,
                funnelLoading: true,
                selectedFunnelPipeline: action.payload.pipeline
            }
        case ACTION.STORE_PIE_PIPELINE_ID:
            return {
                ...state,
                pieLoading: true,
                selectedPiePipeline: action.payload.pipeline
            }
        case ACTION.STORE_FUNNEL_DATA:
            return {
                ...state,
                funnelLoading: false,
                funnelData: action.payload.funnel === undefined ? [] : action.payload.funnel
            }
        case ACTION.STORE_PIE_DATA:
            return {
                ...state,
                pieLoading: false,
                pieData: action.payload.pie === undefined ? [] : action.payload.pie
            }
        case ACTION.STORE_USER_ACTIVITIES:
            return {
                ...state,
                rightListLoading: false,
                userActivities: action.payload.activities
            }
        case ACTION.STORE_USER_TIMELINES:
            return {
                ...state,
                rightListLoading: false,
                timelines: action.payload.timelines
            }
        case ACTION.STORE_USER_NOTIFICATIONS:
            return {
                ...state,
                rightListLoading: false,
                notifications: [...state.notifications, ...action.payload.notifications.data]
            }
        case ACTION.SET_RIGHT_SIDE_LOADER:
            return {
                ...state,
                rightListLoading: action.payload.rightListLoading
            }
        case ACTION.STORE_DASHBOARD_FILTERS:
            return {
                ...state,
                startDate: action.payload.utcStartDate,
                endDate: action.payload.utcEndDate,
                filterUser: action.payload.filterUser
            }

        case ACTION.RESET_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }

        default:
            return state;
    }
}

const conversionRate = (val) => {
    let total = val.win + val.lost + val.opened;
    let rate = (val.win / total) * 100;
    return parseFloat(isNaN(rate) ? 0 : rate).toFixed(1);
}


export default dashboardReducer;
