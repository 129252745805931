import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "400px"
    },
    loaderText: {
        marginTop: "100px"
    }
}));

const Loading = ({ loaderText=null }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress />
            {
                loaderText &&
                <p className={classes.loaderText}>{loaderText}</p>
            }
        </div>
    );
}

export default Loading;