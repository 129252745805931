import React, { useEffect, useState } from "react"
import Utils from "../../../helpers/Utils"
import { DropdownHelper } from "../../common/DropdownHelper";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import HelpIcon from "@material-ui/icons/Help";
let registerStadtardPdf = `${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/standard-lowvolume.pdf`;
const businessTypeList = [
    {label: 'Sole Proprietorship', value: 'Sole Proprietorship'},
    {label: 'Partnership', value: 'Partnership'},
    {label: 'Limited Liability Corporation', value: 'Limited Liability Corporation'},
    {label: 'Co-operative', value: 'Co-operative'},
    {label: 'Non-profit Corporation', value: 'Non-profit Corporation'},
    {label: 'Corporation', value: 'Corporation'},
]

const businessIndustryList = [
    {label: 'AUTOMOTIVE', value: 'AUTOMOTIVE'},
    {label: 'AGRICULTURE', value: 'AGRICULTURE'},
    {label: 'BANKING', value: 'BANKING'},
    {label: 'CONSUMER', value: 'CONSUMER'},
    {label: 'EDUCATION', value: 'EDUCATION'},
    {label: 'ENGINEERING', value: 'ENGINEERING'},
    {label: 'ENERGY', value: 'ENERGY'},
    {label: 'OIL_AND_GAS', value: 'OIL_AND_GAS'},
    {label: 'FAST_MOVING_CONSUMER_GOODS', value: 'FAST_MOVING_CONSUMER_GOODS'},
    {label: 'FINANCIAL', value: 'FINANCIAL'},
    {label: 'FINTECH', value: 'FINTECH'},
    {label: 'FOOD_AND_BEVERAGE', value: 'FOOD_AND_BEVERAGE'},
    {label: 'GOVERNMENT', value: 'GOVERNMENT'},
    {label: 'HEALTHCARE', value: 'HEALTHCARE'},
    {label: 'HOSPITALITY', value: 'HOSPITALITY'},
    {label: 'INSURANCE', value: 'INSURANCE'},
    {label: 'LEGAL', value: 'LEGAL'},
    {label: 'MANUFACTURING', value: 'MANUFACTURING'},
    {label: 'MEDIA', value: 'MEDIA'},
    {label: 'ONLINE', value: 'ONLINE'},
    {label: 'RAW_MATERIALS', value: 'RAW_MATERIALS'},
    {label: 'REAL_ESTATE', value: 'REAL_ESTATE'},
    {label: 'RELIGION', value: 'RELIGION'},
    {label: 'RETAIL', value: 'RETAIL'},
    {label: 'JEWELRY', value: 'JEWELRY'},
    {label: 'TECHNOLOGY', value: 'TECHNOLOGY'},
    {label: 'TELECOMMUNICATIONS', value: 'TELECOMMUNICATIONS'},
    {label: 'TRANSPORTATION', value: 'TRANSPORTATION'},
    {label: 'TRAVEL', value: 'TRAVEL'},
    {label: 'ELECTRONICS', value: 'ELECTRONICS'},
    {label: 'NOT_FOR_PROFIT', value: 'NOT_FOR_PROFIT'}
]

const businessIdentityList = [
    {label: 'Direct Customer', value: 'direct_customer'},
    {label: 'ISV Reseller or Partner', value: 'isv_reseller_or_partner'},
    {label: 'Unknown', value: 'unknown'}
]

const identifierTypeList = [
    {label: 'EIN', value: 'EIN'},
    // {label: 'DUNS', value: 'DUNS'},
    // {label: 'CCN', value: 'CCN'}
]

const typeList = [
    {label: 'Individual', value: 'individual'},
    {label: 'Business', value: 'business'},
]

const BusinessStep = ({data, handleUpdateData, handleStep, handleBack}) => {
    const [businessIdentity, setBusinessIdentity] = useState('direct_customer')
    const [businessType, setBusinessType] = useState(data.business_type)
    const [businessIndustry, setBusinessIndustry] = useState(data.business_industry)
    const [identifierType, setIdentifierType] = useState('EIN')
    const [businessRegistrationIdentifier, setBusinessRegistrationIdentifier] = useState(data.busiess_registration_id)
    const [websiteUrl, setWebsiteUrl] = useState(data.website_url)
    const [customerProfileBusinessInformation, setCustomerProfileBusinessInformation] = useState(data.type)
    const [errorMessage, setErrorMessage] = useState({
       website: ""
    });

    const handleField = (value, field) => {
        if(field === 'businessIdentity'){
            setBusinessIdentity(value)
            handleUpdateData('business', 'business_identity', value)
        }else if(field === "businessType"){
            setBusinessType(value)
            handleUpdateData('business', 'business_type', value)
        }else if(field === "businessIndustry"){
            setBusinessIndustry(value)
            handleUpdateData('business', 'business_industry', value)
        }else if(field === "identifierType"){
            setIdentifierType(value)
            handleUpdateData('business', 'business_registration_id_type', value)
        }else if(field === "businessRegistrationIdentifier"){
            setBusinessRegistrationIdentifier(value)
            handleUpdateData('business', 'busiess_registration_id', value)
        }else if(field === "websiteUrl"){
            setWebsiteUrl(value)
            handleUpdateData('business', 'website_url', value);

            if(!Utils.isValidURL(value)){
                setErrorMessage({ ...errorMessage, website: "Please provide a valid url." });
            }else {
                setErrorMessage({ ...errorMessage, website: "" });
            }
        }else if(field === "customerProfileBusinessInformation"){
            setCustomerProfileBusinessInformation(value)
            handleUpdateData('business', 'type', value)
        }
    }

    const handleNextClick = () => {
        if(
            businessIdentity.trim() === "" || 
            businessType.trim() === "" || 
            businessIndustry.trim() === "" ||
            identifierType.trim() === "" ||
            websiteUrl.trim() === "" ||
            customerProfileBusinessInformation.trim() === "" ||
            businessRegistrationIdentifier.trim() === "" ||
            (errorMessage && errorMessage.website && errorMessage.website.trim() !== "")
        ){
            if(window.showNotification!== undefined){
                window.showNotification("ERROR", "Fill up the form correctly");
            }
            return
        }
        handleStep()
    }

    return(
        <div className="twili__migration___container business___info___step">
            <div className="row" style={{ position: "relative" }}>
                <div className="col s12">
                    <div style={{ position: "absolute", right: "12px", border: "1px solid #dd1616", borderRadius: "4px", padding: "2px"}}>
                        <BootstrapTooltip arrow title={"Standard A2P Registration"}>
                            <a href={registerStadtardPdf} target="_blank" className="ml-2" style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "#dd1616", gap: "2px" }}>
                                Get Help
                                <HelpIcon style={{ color: "#dd1616" }}/>
                            </a>
                        </BootstrapTooltip>
                    </div>
                </div>
            </div>
            {/* <div className="row twilio__migration__droup__down">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Identity *</h6>
                    <DropdownHelper
                        datalist={businessIdentityList}
                        noneFieldRequired={true}
                        noneFieldTitle="Select business identity"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        updateSelectedData={(value) => handleField(value, 'businessIdentity')}
                        selectedValue={businessIdentity}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                    />
                </div>
            </div> */}

            <div className="row twilio__migration__droup__down">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Type *</h6>
                    <DropdownHelper
                        datalist={businessTypeList}
                        noneFieldRequired={true}
                        noneFieldTitle="Select business type"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        updateSelectedData={(value) => handleField(value, 'businessType')}
                        selectedValue={businessType}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="twilio__migration__droup_parentClass actvity-modal-dropdown-time"
                    />
                </div>
            </div>

            <div className="row twilio__migration__droup__down" >
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Industry *</h6>
                    <DropdownHelper
                        datalist={businessIndustryList}
                        noneFieldRequired={true}
                        noneFieldTitle="Select business industry"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        updateSelectedData={(value) => handleField(value, 'businessIndustry')}
                        selectedValue={businessIndustry}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                    />
                </div>
            </div>

            <div className="row twilio__migration__droup__down" >
                <div className="col s12">
                    <div className="twilio__helper_card">
                        <h6 className="twilio__helper__card__title">US Employer Identification Number (US EIN)</h6>

                        <p className="twilio__helper__card_subtitle">EIN is required for customers with a US presence</p>

                        <p className="twilio__helper__card_message">Your 9-digit US EIN is also referred to as your Tax ID. <br/>International customers and customers who do not have a US EIN may skip this step.</p>
                        <p className="twilio__helper__card_message"><b>To get the highest possible messaging limits</b>, create your Customer Profile with the details that match those in your US EIN listing, If there are any differences - for example, in the business name or address - your messaging limits will be lower. Your can find your US EIN on your W2 or W9. <a href="https://www.hipaaspace.com/ein/ein_verification" target="__blank">Search for your US EIN</a></p>
                    </div>
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Identifier Type *</h6>
                    {/*<DropdownHelper*/}
                    {/*    datalist={identifierTypeList}*/}
                    {/*    noneFieldRequired={true}*/}
                    {/*    noneFieldTitle="Select identifier type"*/}
                    {/*    mapping={{ title: "label" ,id: 'value'}}*/}
                    {/*    menuItemClassName=""*/}
                    {/*    updateSelectedData={(value) => handleField(value, 'identifierType')}*/}
                    {/*    selectedValue={identifierType}*/}
                    {/*    dropdownHeader={'ownerChange'}*/}
                    {/*    parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"*/}
                    {/*/>*/}
                    <input
                        readOnly
                        value={identifierType}
                        type="text"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    <p className="twilio__migration__hints"> <span>'USA: Employer Identification Number = EIN' 
                        {/* ,'USA: DUNS Number = DUNS', 
                        'Canada: Canadian Corporation Number= CCN' */}
                        </span></p>
                </div>
            </div>

            {identifierType !== "" &&
            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Business Registration Number ({identifierType}#) *</h6>
                    <input 
                        value={businessRegistrationIdentifier} 
                        onChange={ (e) => handleField(e.target.value, 'businessRegistrationIdentifier')} 
                        type="text" 
                        placeholder="Enter business registration number"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    <p className="twilio__migration__hints"><span>Enter the EIN / TAX ID as it appears in the EIN listing</span></p>
                </div>
            </div>
            }

            <div className="row">
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Website url *</h6>
                    <input 
                        value={websiteUrl} 
                        onChange={ (e) => handleField(e.target.value, 'websiteUrl')} 
                        type="text" 
                        placeholder="Enter website url"
                        className="modal_bordered_text_field radius-5 white"
                    />
                    {
                        errorMessage && errorMessage.website && (
                            <p className="twilio__migration__hints" style={{ color: "red" }}><span>{errorMessage.website}</span></p>
                        )
                    }
                </div>
            </div>

            <div className="row twilio__migration__droup__down" >
                <div className="col s12">
                    <h6 className="twilio__label__v2 text-dark-blue m-0 text-left mb-2">Customer profile business information *</h6>
                    <DropdownHelper
                        datalist={typeList}
                        noneFieldRequired={true}
                        noneFieldTitle="Select customer profile business type"
                        mapping={{ title: "label" ,id: 'value'}}
                        menuItemClassName=""
                        updateSelectedData={(value) => handleField(value, 'customerProfileBusinessInformation')}
                        selectedValue={customerProfileBusinessInformation}
                        dropdownHeader={'ownerChange'}
                        parentDivClassName="ppc__select_owner actvity-modal-dropdown-time"
                    />
                </div>
            </div>

            <div className="row">
                <div 
                    className="col s12 twilio__migration__submit__btn" 
                    style={(
                        businessIdentity.trim() === "" || 
                        businessType.trim() === "" || 
                        businessIndustry.trim() === "" ||
                        identifierType.trim() === "" ||
                        websiteUrl.trim() === "" ||
                        customerProfileBusinessInformation.trim() === "" ||
                        businessRegistrationIdentifier.trim() === "" ||
                        (errorMessage && errorMessage.website && errorMessage.website.trim() !== "")
                    ) ? {'background': '#9e9e9e', 'cursor': 'not-allowed'} : {}} 
                    onClick={handleNextClick}
                >
                    Next
                </div>

                <div 
                    className="col s12 twilio__migration__submit__btn"  
                    onClick={handleBack}
                >
                    Back
                </div>
                
            </div>
        </div>
    )
}
export default BusinessStep;