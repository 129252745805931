export const fetchUserStageInfoReducers = {
    fetchUserStageInfoStart: (state, action) => {
        state.loadingUserStageInfoData = true;

        if (action.payload.selectedPipeline){
            state.selectedPipeline = action.payload.selectedPipeline;
        }
        state.userStageInfoFilterType = action.payload.userStageInfoFilterType;
        if (action.payload.userStageInfoStartDate && action.payload.userStageInfoEndDate) {
            state.userStageInfoStartDate = action.payload.userStageInfoStartDate;
            state.userStageInfoEndDate = action.payload.userStageInfoEndDate;
        }
    },
    fetchUserStageInfoSuccess: (state, action) => {
        state.loadingUserStageInfoData = false;
        state.stageInformation = action.payload.data;
        state.stageInformationCount = action.payload.totalCount;
    },
    fetchUserStageInfoFail: (state, action) => {
        state.loadingUserStageInfoData = false;
        state.error = action.payload;
    },
}