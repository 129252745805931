import React, {Fragment} from "react";
import { Grid, styled } from "@material-ui/core";
import BasicTab from "../common/BasicTab";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import GoalChart from "./GoalChart";
import GoalList from "./GoalList";
import {DASHBOARD_GOAL_TABS} from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import {useDispatch, useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import {fetchUserSalesGoalStart} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import DailyGoalSkeleton from "../skeleton/DailyGoalSkeleton";

const DailyGoalWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
}));

const DailyGoal = () => {
  const dispatch = useDispatch();
  const {
    goalStatFilter,
    userSaleGoalId,
    loadingUserSalesGoalData
  } = useSelector(selectInsuranceDashboardState);

  return (
    <DailyGoalWrapper className="dailyGoal">
      {
        loadingUserSalesGoalData ?
            <DailyGoalSkeleton/> :
            <Fragment>
              <div className="header">
                <div className="greetings">
                  <Title text={`Hi ${Utils.getAccountData("fullName") ?? "User"},`}/>
                  <SubTitle text="Here's your agency's goal for today"/>
                </div>

                <div className="filters">
                  <BasicTab
                      list={DASHBOARD_GOAL_TABS}
                      activeTab={goalStatFilter}
                      onActiveTab={(id) => {
                        dispatch(fetchUserSalesGoalStart({userSaleGoalId: userSaleGoalId, goalStatFilter: id}))
                      }}
                  />
                </div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <GoalList/>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <GoalChart/>
                </Grid>
              </Grid>
            </Fragment>
      }
    </DailyGoalWrapper>
  );
};

export default DailyGoal;
