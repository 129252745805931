import { Chip } from "@material-ui/core";
import { Group, Person } from "@material-ui/icons";

const UserChip = ({ filterUser }) => {
    return ( 
        <Chip 
            label={ filterUser ? "Me" : "Team" } 
            size="small" 
            variant="outlined" 
            icon={filterUser ? <Person fontSize="small"/> : <Group fontSize="small"/>} 
            clickable/>
     );
}
 
export default UserChip;