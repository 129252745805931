import React, {useEffect, useState} from "react";
import { PieChart, Pie, Cell, Label, Text } from "recharts";
import {useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";

const renderCustomLabel = ({ viewBox }, value) => {
    const { cx, cy } = viewBox;
    return (
        <>
            <Text
                x={cx}
                y={cy - 10}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ fontSize: "24px", fontWeight: "bold", fill: "#141928" }}
            >
                {value}
            </Text>
            <Text
                x={cx}
                y={cy + 15}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ fontSize: "16px", fill: "#141928" }}
            >
                Invoices
            </Text>
        </>
    );
};

const renderPercentageLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <Text
            x={x}
            y={y}
            fill={"#ffffff"}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: "14px",
                fontWeight: "bold",
            }}
        >
            {`${(percent * 100).toFixed(2)}%`}
        </Text>
    );
};

const InvoicePieChart = () => {
    const { stageInformation, stageInformationCount } = useSelector(selectInsuranceDashboardState);
    const [chartData, setChartData] = useState([]);
    const [chartDataColor, setChartDataColor] = useState([]);

    useEffect(()=>{
        if (stageInformation){
            setChartDataColor(stageInformation.map((stage)=>(stage.colorCode)));
            setChartData(stageInformation.map((stage)=>({ name: stage.stage, value: parseFloat(stage.percentage) })));
        }
    },[stageInformation]);

    return (
        <div style={{ outline: "none" }}>
            <svg width={300} height={300} style={{ position: "absolute", overflow: "visible" }}>
                <defs>
                    <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                        <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="rgba(0, 0, 0, 0.1)" />
                    </filter>
                </defs>
            </svg>

            <PieChart
                width={300}
                height={300}
                tabIndex={-1}
                style={{ pointerEvents: "none" }}
            >
                <Pie
                    data={chartData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={150}
                    startAngle={90}
                    endAngle={450}
                    paddingAngle={0}
                    label={(props) => renderPercentageLabel({ ...props, index: props.index })}
                    labelLine={false}
                    isInteractive={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={chartDataColor[index]} />
                    ))}
                </Pie>

                <Pie
                    data={[{ name: "Total Invoices", value: stageInformationCount }]}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={0}
                    outerRadius={50}
                    fill="#fff"
                    isAnimationActive={false}
                    isInteractive={false}
                    filter="url(#shadow)"
                >
                    <Label content={(content)=>renderCustomLabel(content, stageInformationCount)} position="center" />
                </Pie>
            </PieChart>
        </div>
    );
};

export default InvoicePieChart;