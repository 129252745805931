import React, {useContext} from "react";
import { Button, Grid, styled } from "@material-ui/core";
import NewLeadsCard from "./NewLeadsCard";
import OpportunitiesCard from "./OpportunitiesCard";
import TaskCard from "./TaskCard";
import UnreadMessageCard from "./UnreadMessageCard";
import {HistoryProvider} from "../../../App";

export const CardWrapper = styled("div")(({ theme }) => ({
  height: 210,
  padding: 16,
  backgroundColor: "#FFFFFF",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  display: "flex",
  flexDirection: "column",

  "& .MuiButtonBase-root": {
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,

    "& .title": {
      fontSize: 16,
    },

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },

  "& .value": {
    fontSize: 30,
    fontWeight: 600,
  },

  "& .goButton": {},

  "& .body": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height:"100%"
  },
}));

export const GoButton = styled(Button)(({ theme }) => ({
  color: "#3E50F7",
  borderColor: "#DEE0E3",
  borderRadius: 10,

  // fontFamily: "'Inter', sans-serif",
  fontSize: 14,
  fontWeight: 500,
  textTransform: 'capitalize',

  "&:hover": {
    backgroundColor: "#3E50F7 !important",
    color: "#FFFFFF",
    borderColor: "#3E50F7",

    "& .goIcon path": {
      fill: "#FFFFFF",
    },
  },

  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
}));

const DashboardCards = () => {
  const history = useContext(HistoryProvider);

  const goToLink = (link, state, search) => {
    history.push({
      pathname: link,
      state: state,
      search: search,
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={3}>
        <NewLeadsCard goToLink={goToLink}/>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <OpportunitiesCard goToLink={goToLink}/>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <TaskCard goToLink={goToLink}/>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <UnreadMessageCard goToLink={goToLink}/>
      </Grid>
    </Grid>
  );
};

export default DashboardCards;
