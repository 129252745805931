import { Grid } from "@material-ui/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { DATEPICKER_INITIAL_SETTING } from "../../constants/CoreConstants";
import { fetchDashboardCount } from "../../actions/dashboardAction";
import BasicSkeleton from "./skeletons/BasicSkeleton";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import UserChip from "./UserChip";
import { useEffect, useRef } from "react";
import moment from "moment";
import Utils, { storeFiltersDataToLocalStorage } from "../../helpers/Utils";


const DealCountBlock = () => {

    const dispatch = useDispatch();
    const datePickerRef = useRef();
    const { filterUser, startDate, endDate, dealCount, dashboardCountLoading } = useSelector(state => state.dashboardReducer);

    useEffect(() => {
        datePickerRef.current.setStartDate(moment(startDate));
        datePickerRef.current.setEndDate(moment(endDate));
    }, [startDate, endDate]);

    const fetchData = (start, end, filter, utcStart, utcEnd) => {

        dispatch(fetchDashboardCount(
            {
                startDate: start,
                endDate: end,
                filterUser: filter,
                utcStartDate: utcStart,
                utcEndDate: utcEnd,
            }
        ));
    }

    const handleDateCallbackDealCount = (start, end, label) => {

        storeFiltersDataToLocalStorage(label);
        let utcStartDate = start.format('YYYY-MM-DD');
        let utcEndDate = end.format('YYYY-MM-DD');

        let startDate = window.globalTimezoneConversionToDifferentTimezone(utcStartDate + ' 00:00:00', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
        let endDate = window.globalTimezoneConversionToDifferentTimezone(utcEndDate + ' 23:59:59', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
        fetchData(startDate, endDate, filterUser, utcStartDate, utcEndDate);
    }

    return (
        <Grid item xs={4}
            className="dashboard__box dashboard--deal-count d-none" id="dashboard--deal-count-view-control"
            style={{
                boxShadow: "inset 0px 5px 0px #ffddb5", flexBasis: '100%',
                maxWidth: '100%'
            }}>
            <div className="dashboard__box_top d-flex align-items-center justify-content-between">
                <h3 className="m-0">Deal Count</h3>
                <div className="d-flex align-items-center">
                    <BootstrapTooltip title={`Showing ${filterUser ? 'only my' : 'team'} counts`} arrow>
                        <div className="mr-1" onClick={() => fetchData(startDate, endDate, !filterUser)}>
                            <UserChip filterUser={filterUser} />
                        </div>
                    </BootstrapTooltip>
                    <DateRangePicker
                        ref={datePickerRef}
                        initialSettings={{ ...DATEPICKER_INITIAL_SETTING, startDate: moment(startDate), endDate: moment(endDate) }}
                        onCallback={handleDateCallbackDealCount}>
                        <span className="dashboard__box_filter d-flex align-items-center justify-content-center darkBlue">
                            <BootstrapTooltip arrow title="Filter by date">
                                <FilterListIcon />
                            </BootstrapTooltip>
                        </span>
                    </DateRangePicker>
                </div>
            </div>

            <div
                className="dashboard__box_count d-flex align-items-center justify-content-center"
                style={{
                    color: "#ff900e",
                    borderColor: "#FFF1E1",
                    backgroundColor: "#FFDDB5",
                }}>
                <BasicSkeleton
                    loading={dashboardCountLoading}
                    content={dealCount.opened + dealCount.win + dealCount.lost}
                />
            </div>
            <div className="dashboard__box_footer">
                <p className="dashboard__won d-flex align-items-center justify-content-between">
                    <span>Won</span>
                    <span>
                        <BasicSkeleton
                            loading={dashboardCountLoading}
                            content={dealCount.win}
                        />
                    </span>
                </p>
                <p className="dashboard__open d-flex align-items-center justify-content-between">
                    <span>Open</span>
                    <span>
                        <BasicSkeleton
                            loading={dashboardCountLoading}
                            content={dealCount.opened}
                        />
                    </span>
                </p>
                <p className="dashboard__lost d-flex align-items-center justify-content-between">
                    <span>Lost</span>
                    <span>
                        <BasicSkeleton
                            loading={dashboardCountLoading}
                            content={dealCount.lost}
                        />
                    </span>
                </p>
            </div>
        </Grid>
    );
}
export default DealCountBlock;