import React from "react";
import { Button, styled } from "@material-ui/core";

const BasicTabWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#F7F7F8",
  padding: 2,
  borderRadius: 12,

  display: "flex",
  alignItems: "center",
  gap: 2,

  "& .MuiButton-contained": {
    color: "#14151A",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DEE0E3",
  },
  "& .MuiButton-text": {
    color: "#0F132499",
    paddingInline: 16,
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },
}));
const TabButton = styled(Button)(({ theme }) => ({
  // fontFamily: "'Inter', sans-serif",
  fontSize: 16,
  fontWeight: 500,
  textTransform: "capitalize",
  paddingBlock: 4,
  borderRadius: 10,
}));

const BasicTab = ({ list, activeTab, onActiveTab, className = "" }) => {
  return (
    <BasicTabWrapper className={className}>
      {list.map((item) => (
        <TabButton
          variant={item.id === activeTab ? "contained" : "text"}
          disableElevation
          onClick={() => onActiveTab(item.id)}
        >
          {item.title}
        </TabButton>
      ))}
    </BasicTabWrapper>
  );
};

export default BasicTab;

/* <Stack
justifyContent={'space-between'}
mt={1}
padding={0.5}
borderRadius={1}
direction={'row'}
sx={{ background: theme.palette.other.outlinedBorder }}
gap={1}
>
<Button
    variant={scheduleTab === EMAIL_SCHEDULE_TABS.SAME_DAY ? 'contained' : 'tonal'}
    color={scheduleTab === EMAIL_SCHEDULE_TABS.SAME_DAY ? 'inheritWhite' : 'inherit'}
    fullWidth
    onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.SAME_DAY)}
    sx={{'&:focus': {bgcolor: 'common.white'}}}
>
    Same Day
</Button>
<Button
    variant={scheduleTab === EMAIL_SCHEDULE_TABS.LATER_DAY ? 'contained' : 'tonal'}
    color={scheduleTab === EMAIL_SCHEDULE_TABS.LATER_DAY ? 'inheritWhite' : 'inherit'}
    fullWidth
    onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.LATER_DAY)}
    sx={{'&:focus': {bgcolor: 'common.white'}}}
>
    Later Day
</Button>
<Button
    variant={scheduleTab === EMAIL_SCHEDULE_TABS.RECURRING ? 'contained' : 'tonal'}
    color={scheduleTab === EMAIL_SCHEDULE_TABS.RECURRING ? 'inheritWhite' : 'inherit'}
    fullWidth
    onClick={() => setScheduleTab(EMAIL_SCHEDULE_TABS.RECURRING)}
    sx={{'&:focus': {bgcolor: 'common.white'}}}
>
    Recurring
</Button>
</Stack> */
