import { all } from "redux-saga/effects";
import dashboardMiddleware from "./dashboardMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            dashboardMiddleware()
        ]
    )
}
