const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/dashboard`
const contactRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const javaBackEndUrl = `${process.env.REACT_APP_JAVAEND_URL}/${process.env.REACT_APP_API_VERSION}/`
const containerRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`

const Api = {
    dashboardCount: `${rootUrl}/dashboard-count`,
    pipelineSources: `${rootUrl}/pipelines-sources`,
    chartData: `${rootUrl}/chart-data`,
    userActivities : `${rootUrl}/user-activity`,
    userTimelines : `${rootUrl}/user-timeline`,
    // userNotifications : `${rootUrl}/user-notifications`,
    userNotifications : `${rootUrl}/user-notifications-with-pagination`,
    userAlertMessage : `${rootUrl}/user-alert-message`,
    userSettingData : `${rootUrl}/user-settings-data`,
    twilioMigrationInfo : `${rootUrl}/get-twilio-term-condition`,
    submitTwilioMigrationInfo : `${rootUrl}/create-twilio-standard-profile`,
    getClaimContactDetails : `${contactRootUrl}/get-claim-contact-details`,
    contactClaim : `${contactRootUrl}/contact-claim`,
    currentCredit: `${containerRootUrl}/user/current-credit`,

    getA2pBrandList: `${containerRootUrl}/user/get-a2p-brand-list`,
    getMessagingService: `${containerRootUrl}/user/get-messaging-services`,
    createCampaignUseCase: `${containerRootUrl}/user/create-campaign-use-case`,
    createCampaignUseCasev2: `${rootUrl}/create-campaign-use-case-registration`,
    requestForSoleProprietorRegistration: `${rootUrl}/request-for-sole-proprietor-registration`,
    checkSoleProprietorshipFee: `${containerRootUrl}/check-sole-proprietorship-fee`,
    getTwilioCampaignUseCase: `${rootUrl}/get-twilio-campaign-use-cases`,
    resendSoleProprietorOtp: `${rootUrl}/resend-sole-proprietor-otp`,
    closeA2pModal: `${rootUrl}/close-a2p-modal`,

    //View Control
    getViewControlData: `${containerRootUrl}/view-control/get-module-info-for-view`,

    // a2p popup check
    checkUserA2pPopupRequired: `${rootUrl}/check-user-a2p-popup-required`,

    // to delete user alert message permanently
    deleteUserAlertMessage: `${rootUrl}/delete-user-alert-message`,

    userAlertMessages : `${rootUrl}/user-alert-messages`,

}

export default Api;