import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab"

const RightListSkeleton = () => {
    return ( 
        Array.apply(null, Array(5)).map((x,i) => {
        return <ListItem key={i} className="activity__singleItem">
                <ListItemAvatar>
                    <Skeleton animation={'wave'} variant="circle" width={40} height={40}/>
                </ListItemAvatar>
                <ListItemText 
                    primary={<span><Skeleton animation={'wave'} width={100}/></span>}
                    secondary={<Skeleton animation={'wave'}/>}
                />
                <span className="activity__hours">
                    <Skeleton animation={'wave'} width={40}/>
                </span>
            </ListItem>
        })
     );
}
 
export default RightListSkeleton;