import React from "react";
import {Grid} from "@material-ui/core";
import {TaskIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const Task = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EachActivityWrapper bgColor={"#F7F1FF"}>
                    <div className="goalIcon">
                        <TaskIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={activity?.totalTaskCompleted ?? 0} />
                        <SubTitle text={"Total Task Completed"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Task;