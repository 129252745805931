export const CloseIcon = ({width="19", height="19", fill="#546376"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 5.3075L13.6925 4.25L9.5 8.4425L5.3075 4.25L4.25 5.3075L8.4425 9.5L4.25 13.6925L5.3075 14.75L9.5 10.5575L13.6925 14.75L14.75 13.6925L10.5575 9.5L14.75 5.3075Z" fill={fill}/>
        </svg>
    );
}
export const ThumbsUpIcon = ({width="24", height="24", fill="#000000"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={`${height}px`} viewBox="0 0 24 24" width={`${width}px`} fill={fill}>
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
            <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
        </svg>
    );
}
export const CheckCircleIcon = ({width="24", height="24", fill="#000000"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55 19.91a1.72 1.72 0 0 1-1.19-.49l-6.2-6.21a.74.74 0 0 1 0-1.06l2.41-2.42a1.73 1.73 0 0 1 2.43 0l2.78 2.79a.18.18 0 0 0 .25 0l7.35-7.35a1.72 1.72 0 0 1 2.38 0l1.45 1.45a1.68 1.68 0 0 1 0 2.37L10.74 19.42a1.72 1.72 0 0 1-1.19.49Zm-5.8-7.23 5.67 5.67a.18.18 0 0 0 .25 0L20.1 7.93a.18.18 0 0 0 0-.25l-1.45-1.45a.19.19 0 0 0-.26 0L11 13.58a1.72 1.72 0 0 1-2.37 0l-2.74-2.79a.16.16 0 0 0-.26 0l-1.88 1.89Z" fill={fill}/>
        </svg>
    );
}