import { Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MiddleListSkeleton } from './skeletons/MiddleListSkeleton';


const ContactSourcesListBlock = () => {

    const { contactSourceLoading, contactSourceCount } = useSelector(state => state.dashboardReducer);

    const renderSourcesList = () => {
		let totalValue = contactSourceCount.reduce((total, obj) => obj.value + total, 0);

		let cards = contactSourceCount.map((data, index) => {
			let percentage = ((data.value / totalValue) * 100).toFixed(2);
			return (
				<Card key={index} className="stage-distribution-card">
					<h5>{data.name}</h5>
					<span>
						Total {data.value} contacts
						<span className="accent--color">
							{percentage}%
						</span>
					</span>
					<div
						className="progressBar accent-bg-opacity">
						<span className="accent--bg--color"
							style={{ width: percentage + "%" }}></span>
					</div>
				</Card>
			);
		});
		return <div className="stage-distribution-card-wrapper">{cards}</div>;
	};

    return (
        <div
			className="dashboard__box dashboard--contact-sources dashboard__lastRow h256px d-none" id="dashboard--contact-sources-view-control"
            style={{ boxShadow: "inset 0px 5px 0px var(--accent-color)" }}
            >
            <div className="dashboard__box_top d-flex align-items-center justify-content-between">
                <h3 className="m-0">Contact Sources</h3>
            </div>

            <div className="dashboard__contact_source">
                {
                    contactSourceLoading ? <MiddleListSkeleton/> : renderSourcesList()
                }
                
            </div>
        </div>
     );
}
 
export default ContactSourcesListBlock;