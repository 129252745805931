import React from "react";
import { AchievedIcon, StarIcon, TargetIcon } from "../common/Icons";
import GoalItem from "./GoalItem";
import { styled } from "@material-ui/core";
import {useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";

const GoalListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
}));

const GoalList = () => {
    const {
        startedTodayAt,
        shouldBeAt,
        goalForToday
    } = useSelector(selectInsuranceDashboardState);
  return (
    <GoalListWrapper className="goalList">
      <GoalItem
        text="STARTED TODAY AT"
        value={Utils.formatCurrency(Utils.toZero(startedTodayAt), "$")}
        icon={<StarIcon />}
        bgColor={"#ECF4FF"}
      />
      <GoalItem
        text="SHOULD BE AT"
        value={Utils.formatCurrency(Utils.toZero(shouldBeAt), "$")}
        icon={<TargetIcon />}
        bgColor={"#EEFFF5"}
      />
      <GoalItem
        text="GOAL FOR TODAY"
        value={Utils.formatCurrency(Utils.toZero(goalForToday), "$")}
        icon={<AchievedIcon />}
        bgColor={"#F4F6FB"}
      />
    </GoalListWrapper>
  );
};

export default GoalList;
