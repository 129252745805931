import { Card, FormControl, MenuItem, Select } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import {
	DATEPICKER_INITIAL_SETTING,
	DROPDOWN_BOTTOM_SETUP,
} from "../../constants/CoreConstants";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useEffect, useRef } from "react";
import { fetchChartData, fetchDashboardCount } from "../../actions/dashboardAction";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import Utils from "../../helpers/Utils";
import UserChip from "./UserChip";
import { MiddleListSkeleton } from "./skeletons/MiddleListSkeleton";
import moment from "moment";

const StageDistributionListBlock = () => {

	const dispatch = useDispatch();
	const datePickerRef = useRef();
	const { startDate, endDate, filterUser, pieLoading, piePipelines, selectedPiePipeline, pieData } = useSelector(state => state.dashboardReducer);

    useEffect(() => {
        datePickerRef.current.setStartDate(moment(startDate));
        datePickerRef.current.setEndDate(moment(endDate));
    },[startDate, endDate]);

	const fetchAllData = (start,end,filter) => {
        dispatch(fetchDashboardCount(
            { startDate : start, endDate : end,filterUser : filter }
        ));
    }

	const fetchData = (start,end,selectedPipeline) => {
        dispatch(fetchChartData({
			startDate: start,
			endDate: end,
			box: "pie",
			pipeline: selectedPipeline,
			filterUser : filterUser
		}));
    }


	const onChangePipeline = (e) => {
		fetchData(startDate,endDate,e.target.value);
	};

	const handleDateCallbackPie = (start, end, label) => {
		Utils.storeFiltersDataToLocalStorage(label);
		fetchAllData(start.format("YYYY-MM-DD"),end.format("YYYY-MM-DD"),filterUser);
	};

	const renderSelectMenu = () => {
		return piePipelines.map((elem, index) => {
			return (
				<MenuItem className="dropdownhelper-menuitem-class d-flex" key={index} value={elem.id}>
					{elem.title}
				</MenuItem>
			);
		});
	};

	const renderDistribution = () => {
		let totalValue = pieData.reduce((total, obj) => obj.dv + total, 0);
		let cards = pieData.map((data, index) => {
			let percentage = ((data.dv / totalValue) * 100).toFixed(2);
			if(isNaN(percentage)) {
				percentage = 0;
			}
			return (
				<Card key={index} className="stage-distribution-card">
					<h5>{data.stage}</h5>
					<span>
						Total {data.dc} deals, Total value{" "}
						{"$" + Utils.abbreviateNumber(data.dv.toFixed(2))}{" "}
						<span style={{ color: data.color_code }}>
							{percentage}%
						</span>
					</span>
					<div
						className="progressBar"
						style={{
							backgroundColor: Utils.convertColorCodeToRGBA(
								data.color_code,
								0.2
							),
						}}>
						<span
							style={{
								backgroundColor: data.color_code,
								width: percentage + "%",
							}}></span>
					</div>
				</Card>
			);
		});
		return <div className="stage-distribution-card-wrapper">{cards}</div>;
	};

	return (
		<div
			className="dashboard__box dashboard--stage-distribution mb-3 d-none" id="dashboard--stage-distribution-view-control"
			style={{ boxShadow: "inset 0px 5px 0px var(--accent-color)" }}>
			<div className="dashboard__box_top d-flex align-items-center justify-content-between">
				<h3 className="m-0">Stages Distribution</h3>
                <div className="d-flex align-items-center">
					<BootstrapTooltip title={`Showing ${filterUser ? 'only my' : 'team'} counts`} arrow>
						<div className="mr-1" onClick={() => fetchAllData(startDate,endDate,!filterUser)}>
							<UserChip filterUser={filterUser}/>
						</div>
					</BootstrapTooltip>
					<DateRangePicker
						ref={datePickerRef}
                        initialSettings={{...DATEPICKER_INITIAL_SETTING, startDate : moment(startDate), endDate : moment(endDate)}}
						onCallback={handleDateCallbackPie}>
						<span className="dashboard__box_filter d-flex align-items-center justify-content-center darkBlue">
							<BootstrapTooltip arrow title="Filter by date">
								<FilterListIcon />
							</BootstrapTooltip>
						</span>
					</DateRangePicker>
				</div>
			</div>

			<div className="dashboard__stages update_dashboard__stages">
				<FormControl className="dashboard__select" variant="filled">
					<Select
						value={selectedPiePipeline}
						onChange={onChangePipeline}
						MenuProps={DROPDOWN_BOTTOM_SETUP}>
						{renderSelectMenu()}
					</Select>
				</FormControl>
			</div>

			{pieLoading ? (
				<MiddleListSkeleton />
			) : pieData.length === 0 ? (
				<p className="d-flex justify-content-center">
					No deals available to generate list
				</p>
			) : (
				renderDistribution()
			)}
		</div>
	);
};

export default StageDistributionListBlock;
