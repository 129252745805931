import React from "react";
import { IconButton } from "@material-ui/core";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {ArrowRightIcon, CalenderIcon} from "../common/Icons";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import { CardWrapper, GoButton } from "./index";
import {useDispatch, useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import UserChip from "../common/UserChip";
import {fetchActiveDealCountStart} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import {DATEPICKER_INITIAL_SETTING, FILTER_TYPE_TEAM, FILTER_TYPE_USER} from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import InsuranceCommonSkeleton from "../skeleton/InsuranceCommonSkeleton";


const OpportunitiesCard = ({ goToLink }) => {
  const dispatch = useDispatch();
  const { loadingActiveDealCount, totalActiveDealCountFilterType, totalActiveDealCount, totalActiveDealStartDate, totalActiveDealEndDate } = useSelector(selectInsuranceDashboardState);

  const handleDateCallback = (start, end) => {
      let utcStartDate = start.format('YYYY-MM-DD');
      let utcEndDate = end.format('YYYY-MM-DD');

      let startDate = window.globalTimezoneConversionToDifferentTimezone(utcStartDate + ' 00:00:00', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
      let endDate = window.globalTimezoneConversionToDifferentTimezone(utcEndDate + ' 23:59:59', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss')
      dispatch(fetchActiveDealCountStart({ totalActiveDealStartDate: startDate, totalActiveDealEndDate: endDate, totalActiveDealCountFilterType: totalActiveDealCountFilterType }))
  }

  return (
    <CardWrapper className="opportunitiesCard">
      <div>
        <div className="header">
          <Title text={"Opportunities"} className="title" />

          <div className="actions">
            <UserChip filterUser={totalActiveDealCountFilterType} onClickAction={() => dispatch(fetchActiveDealCountStart({ totalActiveDealStartDate: totalActiveDealStartDate, totalActiveDealEndDate: totalActiveDealEndDate, totalActiveDealCountFilterType: totalActiveDealCountFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM }))}/>

            <div>
              <DateRangePicker
                  initialSettings={{
                    ...DATEPICKER_INITIAL_SETTING,
                    startDate: moment('09/01/2020'),
                    endDate: moment(),
                  }}
                  onCallback={handleDateCallback}
              >
                  <IconButton size="small">
                      <CalenderIcon />
                  </IconButton>
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>

        {
            loadingActiveDealCount ?
                <InsuranceCommonSkeleton/> :
                <div className="body">
                    <div>
                        <Title text={totalActiveDealCount} className="value"/>
                        <SubTitle text={"Opportunities"}/>
                    </div>
                    <GoButton
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowRightIcon className="goIcon"/>}
                        fullWidth
                        onClick={() => goToLink('/deal-stages')}
                    >
                        Go
                    </GoButton>
                </div>
        }
    </CardWrapper>
  );
};

export default OpportunitiesCard;
