import { FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DATEPICKER_INITIAL_SETTING, DROPDOWN_BOTTOM_SETUP, FUNNEL_SETUP } from "../../constants/CoreConstants";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartData, fetchDashboardCount } from "../../actions/dashboardAction";
import { useEffect, useRef } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loading from "../common/Loading";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import UserChip from "./UserChip";
import moment from "moment";
import { storeFiltersDataToLocalStorage } from "../../helpers/Utils";
require("highcharts/modules/funnel")(Highcharts);

const FunnelBlock = () => {

    const dispatch = useDispatch();
    const chartRef = useRef();
    const datePickerRef = useRef();
    const { startDate, endDate, filterUser, funnelLoading, funnelPipelines, selectedFunnelPipeline } = useSelector(state => state.dashboardReducer);

    useEffect(() => {
        datePickerRef.current.setStartDate(moment(startDate));
        datePickerRef.current.setEndDate(moment(endDate));
    },[startDate, endDate]);
    
    const funnelData = useSelector(state => {
        let data = state.dashboardReducer.funnelData;
        if (data.length > 0) {

            var objectArray = [];
            var colors = [];

            for (let i = 0; i < data.length; i++) {
                try{
                    if(data[i] && data[i].stage && data[i].dc){
                        objectArray.push([data[i].stage,parseInt(data[i].dc)]);
                        colors.push(data[i].color_code);
                    }
                    
                }
                catch(e){
                    
                }
                
            }

            let funnelLocalData = FUNNEL_SETUP;
            funnelLocalData.series[0].data = objectArray;
            funnelLocalData.series[0].colors = colors;
            if(chartRef.current !== undefined && chartRef.current !== null) {
                chartRef.current.chart.series[0].update({
                    data: objectArray,
                    colors:colors
                }, true);
            }
            return funnelLocalData;
        } else {
            return null;
        }
        
    });

    const fetchAllData = (start,end,filter) => {
        dispatch(fetchDashboardCount(
            { startDate : start, endDate : end,filterUser : filter }
        ));
    }

    const fetchData = (start,end,selectedPipeline) => {
        dispatch(fetchChartData({
            startDate : start,
            endDate : end,
            box : 'funnel',
            pipeline : selectedPipeline,
            filterUser : filterUser
        }));
    }

    const renderSelectMenu = () => {
        return funnelPipelines.map((elem,index) => {
            return <MenuItem className="dropdownhelper-menuitem-class d-flex" key={index} value={elem.id}>{elem.title}</MenuItem>
        })
    }

    const onChangePipeline = (e) => {
        fetchData(startDate,endDate,e.target.value);
    }

    const handleDateCallbackFunnel = (start, end, label) => {
        storeFiltersDataToLocalStorage(label);
        fetchAllData(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'),filterUser);
    }

    return ( 
        <Grid item xs={7}
            className="dashboard__box dashboard--funnel-block largeChart h100_percent d-none" id="dashboard--funnel-block-view-control"
            style={{ boxShadow: "inset 0px 5px 0px var(--accent-color)", minWidth: '58.999%', maxWidth: '100%', flexBasis: '100%' }}>
            <div className="dashboard__box_top d-flex align-items-center justify-content-between">
                <h3 className="m-0">Funnel</h3>
                <FormControl
                    className="dashboard__select"
                    variant="filled">
                    <Select
                        value={selectedFunnelPipeline}
                        onChange={onChangePipeline}
                        className="funnel__dropdown"
                        MenuProps={DROPDOWN_BOTTOM_SETUP}>
                        {
                            renderSelectMenu()
                        }
                    </Select>
                </FormControl>
                <div className="d-flex align-items-center">
                    <BootstrapTooltip title={`Showing ${filterUser ? 'only my' : 'team'} counts`} arrow>
                        <div className="mr-1" onClick={() => fetchAllData(startDate,endDate,!filterUser)}>
                            <UserChip filterUser={filterUser}/>
                        </div>
                    </BootstrapTooltip>
                    <DateRangePicker
                        ref={datePickerRef}
                        initialSettings={{...DATEPICKER_INITIAL_SETTING, startDate : moment(startDate), endDate : moment(endDate)}}
                        onCallback={handleDateCallbackFunnel}>
                        <span className="dashboard__box_filter d-flex align-items-center justify-content-center darkBlue">
                            <BootstrapTooltip arrow title="Filter by date">
                                <FilterListIcon />
                            </BootstrapTooltip>
                        </span>
                    </DateRangePicker>
                </div>
            </div>

            <div className="funnelChart">
                {
                    funnelLoading ? <Loading/> : 
                    funnelData === null ?
                    <p className="d-flex justify-content-center">No deals available to generate funnel</p> :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={funnelData}
                        ref={ chartRef }
                    />
                }
                
            </div>
        </Grid>
     );
}
 
export default FunnelBlock;