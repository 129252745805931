import React, {useContext, useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import DashboardSidebar from './RightSidebar/DashboardSidebar';
import DealCountBlock from "./DealCountBlock";
import DealValueBlock from "./DealValueBlock";
import ConversionRateBlock from "./ConversionRateBlock";
import FunnelBlock from "./FunnelBlock";
import ContactSourcesListBlock from "./ContactSourcesListBlock";
import {fetchDashboardCount, fetchPipelineSources} from "../../actions/dashboardAction";
import {useDispatch} from "react-redux";
import Utils, {getAccountData} from "../../helpers/Utils";
import StageDistributionListBlock from "./StageDistributionListBlock";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./CustomCss.css";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
//moved to cdn
import {
	checkUserA2pPopupRequiredApi,
	deleteUserAlertMessageApi,
	getCurrentCreditApi,
	getUserAlertMessagesApi,
	getViewControlDataApi,
	resendSoleProprietorOtpApi
} from "../../api/dashboardApi";
import {DashboardAlertPopup} from "./DashboardAlertPopup";
import CreditAlert from "./CreditAlert";
import {HistoryProvider} from "../../App";
import {isArray} from "highcharts";
import {CloseIcon} from "../ten-dlc/helpers/Icon";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import BrowserAlert from "./BrowserAlert";
import NotificationPopupAlert from "../notificationPopupAlert/NotificationPopupAlert";
import NylasV3MigrationAlert from "../nylasV3MigrationAlert";
import {getUserInfo, userConnectedEmails} from "../nylasV3MigrationAlert/ApiService";
import {connectedEmailStatus, emailProvider, NYLAS_V3_AGENCIES} from "../../constants/CoreConstants";

const userId = getAccountData("userId");


const Dashboard = () => {
	const dispatch = useDispatch();
    const history = useContext(HistoryProvider);
	const [userNotifications, setUserNotifications] = useState([])
	const [isShowPopUp, setIsShowPopUp] = useState(false)
	const [creditValue, setCreditValue] = useState(null)
	const [creditPopup, setCreditPopup] = useState(false)
	const [updateView, setUpdateView] = useState(false)
	const [viewControlArray, setViewControlArray] = useState([])
	const isTempUser = Utils.getAccountData('isTemplateUser');
	const starterPack = Utils.getAccountData('starterPack');
	const [isResending, setIsResending] = useState(false)
	const [alertFor, setAlertFor] = useState("twilio_migration_alert");
	const [openBrowserAlert, setOpenBrowserAlert] = useState(false);
	const trialRemainingDays = Utils.getAccountData('trialRemainingDays');
	const [openNotificationAlert, setOpenNotificationAlert] = useState(false);
	const [openNylasMigrationV3Alert, setOpenNylasMigrationV3Alert] = useState(false);
	const [userData, setUserData] = useState(null);
	const [connectedFailedEmail, setConnectedFailedEmail] = useState(null);
	const lowFundPopup = Utils.getAccountData('lowFundPopup');

	useEffect(() => {

		window.onload = function afterWebPageLoad() { 
			if(setIsShowPopUp!== undefined){
				setIsShowPopUp(true)
			}
		}

		document.title = `Dashboard | ${Utils.getAccountData('AuthUserAgencyName')}`;
		if(window.setActiveSidebar) {
			window.setActiveSidebar('dashboard');
		}

		//getting filters data from local storage
		let localStoredData = Utils.getFiltersStoredData();
		
		dispatch(fetchDashboardCount({
			startDate : localStoredData.startDate,
			endDate : localStoredData.endDate,
			filterUser : localStoredData.filterUser,
			initCall : true
		}));
		
		dispatch(fetchPipelineSources({
			startDate : localStoredData.startDate,
			endDate : localStoredData.endDate,
			filterUser : localStoredData.filterUser
		}));

		getUserNotification();

		getCreditValue();

		checkUserA2pPopupRequired();

		checkUserAgent();
		if (NYLAS_V3_AGENCIES.includes(Utils.getAccountData('agencyId'))){
			getUserData().then();
		}
	},[]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(()=>{

		getViewControlDataApi({module : 1}).then(res => {
				

			if(res.data.data && isArray(res.data.data)){

				let tmp = res.data.data;
				tmp.push('dashboard-sidebar-view-control')
				setViewControlArray(tmp);
			}
			}).catch(error => {
				console.log("error on getting dashboard view control", error)
			})

	},[updateView])

	useEffect(()=>{

		let newData = [];
		 viewControlArray.forEach((identifier)=>{

			let selector = document.getElementById(identifier);
			if(selector){
				selector.classList.remove('d-none');
			}
			else{
				newData.push(identifier);
			}
		})

		if(newData.length > 0){
			setViewControlArray(newData)
		}

	},[viewControlArray])

	useEffect(() => {
		if(!isTempUser && !starterPack){
			if(creditValue && creditValue<=0.5){
				if(lowFundPopup === "1") {
					setCreditPopup(true);
				}
			}
		}
	},[creditValue]); 

	useEffect(()=>{
		if(userNotifications){

		const openA2pCampaignPopupRef = window.document.querySelector(".dashboard-attention-message-event .open-a2p-campaign-registration-popup");
		const openEinPopupRef = window.document.querySelector(".dashboard-attention-message-event .open-ein-popup");
		const openA2pRegistrationPopupRef = window.document.querySelector(".dashboard-attention-message-event .open-a2p-registration-popup");

		if(openA2pCampaignPopupRef){

			openA2pCampaignPopupRef.addEventListener("click",  (ev) => {
				ev.preventDefault();
				setAlertFor("twilio_migration_campaign_registration")
	
			});
		}

		if(openEinPopupRef){
			openEinPopupRef.addEventListener("click",  (ev) => {
				ev.preventDefault();
				setAlertFor('show-ein-confirmation')
			});
		}

		if(openA2pRegistrationPopupRef){
			openA2pRegistrationPopupRef.addEventListener("click",  (ev) => {
				ev.preventDefault();
				setAlertFor('show-volume-select-screen')
			});
		}
		
		}

	},[userNotifications])

	const getCreditValue = () =>{
		getCurrentCreditApi().then(res => {
			let response = res.data;
			if(response.status){
				setCreditValue(response.currentCredit);
			}
		}).catch(error => {
			console.log("error on Credit Value", error)
		})
	}

	const getUserNotification = () => {
		if(userNotifications[0]){
			return;
		}
		getUserAlertMessagesApi().then(res => {
			let response = res.data;
			if(response.message != null){
				setUserNotifications(response.message)
			}else {
				setUserNotifications([]);
			}
		}).catch(error => {
			console.log("error on getting user notification", error)
		});
	}

	const forceUpdateUserNotification = () => {
		getUserAlertMessagesApi().then(res => {
			let response = res.data;
			if(response.message != null){
				setUserNotifications(response.message)
			}else {
				setUserNotifications([]);
			}
		}).catch(error => {
			console.log("error on getting user notification", error)
		});
	}

	const getNotificationBody = (text) => {
		let message = "";
		if(text && text.includes("[[name]]")) {
			message = text.replace("[[name]]", Utils.getAccountData('fullName'));
		}
		else if(text && text.includes("[[email]]")){
			message = text.replace("[[email]]", Utils.getAccountData('email'));
		}
		else{
			message = text;
		}
		return message;
	}

	const checkUserA2pPopupRequired = () => {
		try{
			checkUserA2pPopupRequiredApi()
				.then((res)=>{
					res = res.data;
					if(res.success){
						let info = res.data;
						if (info.need_to_migration === 1){
							window.globalA2p10DlcOnBoard(true,info);
						}else if((info.need_to_migration === 0 &&
							!info.campaign_setting_available &&
							info.need_campaign_registration)
						){
							window.globalA2pCampaignUseCaseRegistration(true,info);
						}
					}
				})
				.catch((err)=>{
					console.log(err);
				});
		}catch (err){
			console.log(err);
		}
	}

	const setCookie = (name, value, days) => {
		try{
			let expires = "";
			if (days) {
				const date = new Date();
				date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + value + expires + "; path=/";
		}catch (err){
			console.log("Failed to set cookie");
		}
	}

	const getCookie = (name) => {
		try{
			const cookieArray = document.cookie.split("; ");
			for (let i = 0; i < cookieArray.length; i++) {
				const cookiePair = cookieArray[i].split("=");
				if (cookiePair[0] === name) {
					return decodeURIComponent(cookiePair[1]);
				}
			}
			return null;
		}catch (err){
			return null;
		}
	}

	const checkUserAgent = () => {
		try{
			const userAgent = navigator.userAgent;
			if ((
				userAgent.includes("Edge") ||
				userAgent.indexOf("MSIE") !== -1 ||
				userAgent.indexOf("Trident/") !== -1 ||
				userAgent.includes("Edg")
			) &&
				!getCookie("__ppa_browser_consent")
			) {
				setOpenBrowserAlert(true);
			}
		}catch (err){
			console.log(err);
		}
	}

	const renderClassName = (alert_type) => {
		if(alert_type != null){
			if(alert_type === 1){
				return 'dashboard___attention_danger';
			}else if(alert_type === 2){
				return 'dashboard___attention_success';
			}else if(alert_type === 3){
				return 'dashboard___attention_info';
			}else if(alert_type === 4){
				return 'dashboard___attention_warning';
			}
		}
		return '';
	}

	const reRoute=(pathname)=>{
		history.push({
			pathname: pathname,
			state: { from:'credit-low', tab :2 } //2 for instant top up
		});
	}

	const resendSoleOtp = () => {
		setIsResending(true);
		resendSoleProprietorOtpApi()
			.then((res)=>{
				if (res.data && res.data.success){
					window.showNotification("success", res.data.message);
				}else {
					window.showNotification("error", res.data.message);
				}
			})
			.catch((err)=>{
				window.showNotification("error", "Something went wrong!!");
			})
			.finally(()=>{
				setIsResending(false);
			});
	}

	const renderTrialAlert = () => {
		return <>
			<div style={{ position: "relative", padding: 10 }} className={`dashboard___attention dashboard___attention_info`}>
				<div className="dashboard___attention__message w-100">
					<div className="dashboard___attention__message__text">
						<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
							<Box sx={{display: 'flex', alignItems: 'center',}}>
								<CalendarTodayIcon />
							</Box>

							<div className="dashboard-attention-message-event" dangerouslySetInnerHTML={{ __html:getNotificationBody(`Your free trial expires in ${trialRemainingDays} days`) }} />
							<a href="/billing"><Button style={{color: '#181F48', backgroundColor: '#00ff91'}}>Upgrade Now</Button></a>
						</Box>
					</div>
				</div>
			</div>
		</>
	}

	const renderAlertMessage = () => {
		let view = [];

		for (let i=0;i<userNotifications.length;i++){
			let userNotification = userNotifications[i];
			if (userNotification.type === 'SOLE_PROPRIETOR_OTP_REQUIRED'){
				view.push(
					<div style={{ position: "relative" }} className={`dashboard___attention ${renderClassName(userNotification?.system_notification?.alert_type)}`}>
						<div className="dashboard___attention__message w-100">
							<div className="d-flex justify-content-between">
								{
									i === 0 &&
									<div className="d-flex align-content-center dashboard___attention__message__label">
										<AnnouncementIcon className="mr-2"/>
										Attention!
									</div>
								}
								<div style={{ position: "absolute", right: "5px", top: "5px" }} onClick={()=>{deleteUserAlertMessage(userNotification.id, i)}} className="cursor-pointer">
									<CloseIcon width="27" height="27" fill="#fff"/>
								</div>
							</div>
							<div className="dashboard___attention__message__text">
								<div dangerouslySetInnerHTML={{ __html:getNotificationBody(userNotification?.system_notification?.body) }} />
								<div className={isResending ? "disabled customButtonDiv ml-3" : "customButtonDiv ml-3"} onClick={resendSoleOtp}>Resend OTP</div>
							</div>
						</div>
					</div>
				);
			}else {
				view.push(
					<div style={{ position: "relative" }} className={`dashboard___attention ${renderClassName(userNotification?.system_notification?.alert_type)}`}>
						<div className="dashboard___attention__message w-100">
							{
								i === 0 &&
								<div className="d-flex align-content-center dashboard___attention__message__label">
									<AnnouncementIcon className="mr-2"/>
									Attention!
								</div>
							}
							<div style={{ position: "absolute", right: "5px", top: "5px" }} onClick={()=>{deleteUserAlertMessage(userNotification.id, i)}} className="cursor-pointer">
								<CloseIcon width="27" height="27" fill="#fff"/>
							</div>
							<div className="dashboard___attention__message__text">
								{/* {getNotificationBody(userNotification?.system_notification?.body)} */}
								<div className="dashboard-attention-message-event" dangerouslySetInnerHTML={{ __html:getNotificationBody(userNotification?.system_notification?.body) }} />
							</div>
						</div>
					</div>
				);
			}
		}
		return view;
	}

	function deleteUserAlertMessage(userAlertMessageId,i){
		try{
			deleteUserAlertMessageApi({ userAlertMessageId: userAlertMessageId })
				.then((res)=>{
					let response = res.data;
					if(response.success){
						let oldData = [...userNotifications];
						oldData.splice(i,1);
						setUserNotifications(oldData);
					}
				}).catch((err)=>{
				console.log(err);
			});
		}catch (err){
			console.log(err);
		}
	}

	const nylasMigrationManage = () => {
		try {
			const lastClosedTime = localStorage.getItem('lastClosedTime');
			if (lastClosedTime) {
				const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
				const currentTime = new Date().getTime();
				if (currentTime - parseInt(lastClosedTime) < twentyFourHoursInMillis) {
					setOpenNotificationAlert(false);
				}
			}
		}catch (err){
			setOpenNotificationAlert(false);
		}
	}

	const handleCloseNotificationPopupAlert = () => {
		try{
			localStorage.setItem('lastClosedTime', new Date().getTime().toString());
			setOpenNotificationAlert(false);
		}catch (err){
			setOpenNotificationAlert(false);
		}
	}

	const getFirstFailedConnectedEmail = (data) => {
		return data.find((email) => (email.status === connectedEmailStatus.FAILED));
	};

	const getUseConnectedEmails = async () => {
		try{
			const resp = await userConnectedEmails();
			if (resp && resp.data && resp.data.data){
				if (getFirstFailedConnectedEmail(resp.data.data)){
					setOpenNylasMigrationV3Alert(true);
					setConnectedFailedEmail(getFirstFailedConnectedEmail(resp.data.data));
				}
			}
		}catch (err){
			console.log(err);
		}
	};

	const getUserData = async ()=>{
		try{
			const resp = await getUserInfo();

			if (resp && resp.data){
				if (resp.data.email_provider === emailProvider.NYLAS_V1){
					setOpenNylasMigrationV3Alert(true);
				}else if (resp.data.email_provider === emailProvider.NYLAS_V3){
					await getUseConnectedEmails();
				}
				setUserData(resp.data);
			}
		}catch (err){
			console.log(err);
		}
	};

	return (
		<>
			{
				trialRemainingDays >=0 && renderTrialAlert()
			}

			{
				userNotifications && userNotifications[0] && renderAlertMessage()
			}

			<div className="dashboard__wrapper d-flex columnGap30 rowGap30">

				<Grid item xs={9} className="dashboard__mainArea d-flex flex-column">
					{/* First Row */}
					<div className="d-flex columnGap30 column3" id="#dashboard-view-control-top">
						<DealCountBlock/>
						<DealValueBlock/>
						<ConversionRateBlock/>
					</div>

					{/* Second Row */}
					<div className="d-flex columnGap30 secondRow" id="#dashboard-view-control-second-row">
						<FunnelBlock/>

						<Grid item xs={5} className="rowGap30 flex-column anotherCol lastCol">
							<StageDistributionListBlock/>
							<ContactSourcesListBlock/>
						</Grid>
					</div>
					
				</Grid>
				<Grid item xs={3} className="dashboard__sidebar w378px d-none" id="dashboard-sidebar-view-control">
					<DashboardSidebar />
				</Grid>
				{/* {isShowPopUp && */}
				{alertFor && 
					<DashboardAlertPopup alertFor={alertFor} userType={'all'} setAlertFor={setAlertFor}
					getUserNotification= {forceUpdateUserNotification}/>
				}
			</div>

			<div>
				{creditPopup && <CreditAlert creditPopup={creditPopup} 
				reRoute={(path)=>{setCreditPopup(false);reRoute(path)}}/>}
				
			</div>

			<BrowserAlert
				data={{
					callback: (data) => {
						if (data.useWithRisk){
							setCookie("__ppa_browser_consent", 1, 1);
						}
					}
				}}
				isOpen={openBrowserAlert}
				onClose={()=>{ setOpenBrowserAlert(false) }}
			/>

			<NotificationPopupAlert
				open={openNotificationAlert}
				handleClose={handleCloseNotificationPopupAlert}
			/>

			<NylasV3MigrationAlert
				userData={userData}
				open={openNylasMigrationV3Alert}
				connectedFailedEmail={connectedFailedEmail}
				onClose={()=>{ setOpenNylasMigrationV3Alert(false); }}
			/>
		</>
	);
};

export default Dashboard;
