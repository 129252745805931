import React from "react";
import {Grid} from "@material-ui/core";
import {CallAddIcon, TalkTimeIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const Call = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#F7F1FF"}>
                    <div className="goalIcon">
                        <CallAddIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={activity?.totalCalls ?? 0} />
                        <SubTitle text={"Total Call"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <EachActivityWrapper bgColor={"#F4F6FB"}>
                    <div className="goalIcon">
                        <TalkTimeIcon />
                    </div>

                    <div className="GoalText">
                        <Title text={activity?.totalTalkTime ?? 0} />
                        <SubTitle text={"Total Talk time"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Call;