import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ClaimContact from '../components/claim-contact';

// import components
import Dashboard from '../components/dashboard/Dashboard';
import TwilioMigrationPopup from '../components/dashboard/twilio-migration/TwilioMigrationPopup';
import HealthCheck from '../components/HealthCheck';
import TestSocket from '../components/test-socket/TestSocket';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/'} component={Dashboard}/>
                <Route exact path={'/twilio-setup'} component={TwilioMigrationPopup}/>
                <Route exact path={'/user/claim-contact/:id'} component={ClaimContact}/>
                <Route exact path={'/test-socket'} component={TestSocket}/>
                <Route path={"/dashboard/health-check"} component={HealthCheck}/>
            </Switch>
        </Router>
    );
}

export default Routes;