import React from "react";
import {
    Paper,
    Modal,
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import Icons from "./Icons";

const BrowserAlert = ({ isOpen, onClose, data }) => {

    let browsers = [
        {
            name: "Chrome",
            icon: "/assets/images/browserIcons/chrome.svg",
            link: "https://www.google.com/chrome/"
        },
        {
            name: "Chromium",
            icon: "/assets/images/browserIcons/chromium.svg",
            link: "https://www.chromium.org/Home/"
        },
        {
            name: "Brave",
            icon: "/assets/images/browserIcons/brave.svg",
            link: "https://brave.com/"
        },
        {
            name: "Firefox",
            icon: "/assets/images/browserIcons/firefox.svg",
            link: "https://www.mozilla.org/en-US/firefox/new/"
        },
        {
            name: "Opera",
            icon: "/assets/images/browserIcons/opera.svg",
            link: "https://www.opera.com/"
        },
        {
            name: "Safari",
            icon: "/assets/images/browserIcons/safari.svg",
            link: "https://www.apple.com/safari/"
        }
    ];

    return(
        <Modal
            open={isOpen}
            onClose={()=>{}}
            aria-labelledby="multi-stepper-modal-title"
            aria-describedby="multi-stepper-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                <div className={Styles.headerWrapper}>
                    <div className={Styles.headerInner}>
                        <div onClick={onClose} className={Styles.closeCircleIcon}>
                            {Icons.closeCircle}
                        </div>
                    </div>
                </div>
                <div className={Styles.modalBody}>
                    <div className={Styles.bodyContainer}>
                        <div className={Styles.bodyTextContainer}>
                            <div className={`${Styles.imageContainer} ${Styles.zoomInZoomOut}`}>
                                {Icons.important}
                            </div>
                            <div className={Styles.reasonTitle}>
                                We recommend using the following browsers for optimal performance, as it appears you are currently using <div className={Styles.textStrong}>Internet Explorer/Microsoft Edge</div>
                            </div>
                            <div className={Styles.bwsrIcons}>
                                {
                                    browsers.map((browser)=> (
                                        <a href={browser.link} className={Styles.bwsrIcon} target="_blank">
                                            <img
                                                src={`${process.env.REACT_APP_APP_URL}${browser.icon}`}
                                                alt={browser.name}/>
                                            <span>{browser.name}</span>
                                        </a>
                                    ))
                                }
                            </div>
                            <div className={Styles.actionContainer}>
                                  <span onClick={() => {
                                      data.callback({useWithRisk: true});
                                      onClose();
                                  }} className={`${Styles.riskActionButton} mr-2`}>
                                      Continue Anyway
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </Modal>
    );
};

export default BrowserAlert;