import React, {useContext, useEffect, useState} from "react";
import { ContactClaimApi, getContactDataByIdApi } from "../../api/dashboardApi";
import { HistoryProvider } from "../../App";
import Utils from "../../helpers/Utils";
import './claim.css'

const ClaimContact = (props) => {
    const history = useContext(HistoryProvider);
    const [details, setDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isClaim, setIsCliam] = useState(false);
    const [groupUserNotification, setGroupUserNotification] = useState(null)
    const [claiming, setClaiming] = useState(false)

    useEffect(() => {
        const contactId = props.match.params.id;
        getContactDetails(contactId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContactDetails = (contact_id) => {
        getContactDataByIdApi({hash_code: contact_id})
        .then(res => {
            let response = res.data
            if(response.status === "unauthorized"){
                window.showNotification("ERROR", "Unauthorized access! Please login again.");
            }
            else if(response.status === "error"){
                window.showNotification("ERROR", "No information found for this contact !");
            }else{
                if(response.data.contact != null){
                    setDetails(response.data.contact)
                }
                setIsCliam(response.data.checkClaimed)              
                setGroupUserNotification(response.data.groupUserNotification)              
            }
            setIsLoading(false)
        })
        .catch(error => {
            setIsLoading(false)
        })
    }

    const goToLink = (link, state) => {
        history.push({
            pathname: link,
            state: state
        });
    }

    const handleClaim = () => {
        if(!claiming){
            setClaiming(true)
            ContactClaimApi({
                contact_id: groupUserNotification?.contact_id,
                group_contact_id: groupUserNotification?.group_contact_id
            })
            .then(res => {
                let response = res.data;
                if(response.status === "success"){
                    goToLink(('/contacts/'+groupUserNotification?.contact_id), 'from-claim')
                }
                else if(response.status === "error"){
                    window.showNotification("warning", "Something went wrong! Try again later")
                }
                else if(response.status === "claimed"){
                    window.showNotification("warning", "Sorry! the contact is already claimed by another user")
                }
                setClaiming(false)
            })
            .catch(error => {
                setClaiming(false)
            })
        }
    }

    const renderAvater = () => {
        let text = 'N/A', name = '';
        if(details != null){
            if(!Utils.isEmpty(details.first_name) && !Utils.isEmpty(details.last_name)){
                name = details.first_name + ' ' + details.last_name;
                text = details.first_name.charAt(0) + '' + details.last_name.charAt(0)
            }
            else if(!Utils.isEmpty(details.first_name)){
                name = details.first_name;
                text = details.first_name.substring(0, 2)
            }
            else if(!Utils.isEmpty(details.last_name)){
                name = details.last_name;
                text = details.last_name.substring(0, 2)
            }
        }
        return {
            'avater' : text,
            'name' : name
        };
    }

    const renderEmpty = () => {
        if(isLoading){
            return(
                <div className="empty__claim__data__container">
                    Please wait...
                </div>
            )
        }
        return(
            <div className="empty__claim__data__container">
                No data found !
            </div>
        )
    }

    const renderDetails = () => {
        let des = renderAvater();
        return(
            <div className="claim__contact__details_view">
                <div className="claim__contact_details_header">
                    <h3 className="claim__contact_details_header__title">You have a contact to claim</h3>
                    <div className="claim__contact__avater">
                        <span>{des.avater}</span>
                    </div>
                    <div className="clain_name__info">
                       <div className="claim__contact__avater__title">
                       <span>Name: </span>
                       {des.name}
                       </div>
                       <div  className="claim__contact__avater__title">
                       <span>Email:  </span>{Utils.isEmpty(details.email) ? "No email found !" : details.email}
                       </div>
                        <div  className="claim__contact__avater__title">
                        <span>Number:  </span> {Utils.isEmpty(details.number) ? "No number found !" : details.number}
                        </div>
                    </div>
                </div>

                {!isClaim &&
                <div className="claim__action__button">
                    <div className="claim__button" onClick={handleClaim}>{claiming ? 'Updating Claim' : 'Claim it now'}</div>
                </div>
                }

                {isClaim &&
                <div className="claim__action__button">
                    <div className="claim__button">{'Oops!!! This contact has already claimed by another user.'}</div>
                </div>
                }
            </div>
        )
    }

    return(
        <div className="claim__contact__container">
            {details != null ? renderDetails() : renderEmpty()}
        </div>
    )
}

export default ClaimContact;