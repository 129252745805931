const rootUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/${process.env.REACT_APP_TENANT_API_VERSION}/dashboard`

const Api = {

    fetchInitialDashboardData: `${rootUrl}/get-initial-data`,

    userLeadCount: `${rootUrl}/user/lead-count`,
    teamLeadCount: `${rootUrl}/team/lead-count`,

    teamActiveDealCount: `${rootUrl}/team/active-deal-count`,
    userActiveDealCount: `${rootUrl}/user/active-deal-count`,

    teamAppointmentCount: `${rootUrl}/team/appointments-count`,
    userAppointmentCount: `${rootUrl}/user/appointments-count`,

    teamActivity: `${rootUrl}/team/activity`,
    userActivity: `${rootUrl}/user/activity`,

    teamUserStageInfo: `${rootUrl}/team/user-stage-info`,
    userUserStageInfo: `${rootUrl}/user/user-stage-info`,

    userSaleGoalStats: `${rootUrl}/sales-goal-stats`
}

export default Api;