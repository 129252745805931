import React, { useContext } from "react";
import { HistoryProvider } from "../../App";
import {useHistory, Link} from 'react-router-dom';
import "./popup.css";
import Utils from "../../helpers/Utils";

const LandingPop = (props) => {
    const parentHistory = useContext(HistoryProvider);
    const history = useHistory();

    const goToLink = (e,link, tab=null) => {
        e.preventDefault();
        if(tab == null){
            history.push(link);
        }else{
            parentHistory.push({
                pathname: link,
                state: { from:'settingPage', tab }
            });
        }
        if(props.handleClosePopup !== undefined){
            props.handleClosePopup();
        }
    }

    const goToLinkForReg = (e, link) => {
        e.preventDefault();
        history.push({
            pathname: link,
            state: { from: "dashboard_alert_popup", data: props.information}
        });
        if(props.handleClosePopup !== undefined){
            props.handleClosePopup();
        }
    }

    const renderAutoRecharge = () => {
        if(props.alertFor === 'credit_auto_recharge_alert'){
            return(
                <>
                    <p>Billing Change - Auto Recharge</p>
                    <p>Effective February 16, 2022</p>

                    <p>
                        Your account is currently setup on auto recharge. 
                        When your balance gets low than 
                        <b> ${(props.information['credit_reach'] !== undefined && props.information['credit_reach'] != null) ? props.information['credit_reach'] : 10}</b>, 
                        we automatically recharge you <b>${(props.information['refile_amount'] !== undefined && props.information['refile_amount'] != null) ? props.information['refile_amount'] : 10}</b>. 
                        As of February 16, 2022 we will be removing the <b>$10</b> option. 
                    </p>

                    <p>
                        The minimum recharge option will now be <b>$25</b>. Your account will automatically be updated to the <b>$25</b> option, but if you wish to increase the threshold even higher you can do so here.
                    </p>

                    <p >
                        Settings &gt; Billing Info &gt;<span className="cursor-pointer" style={{color: "#4285f4"}} onClick={(e) => goToLink(e,'/billing', 3)}><b> Recharge</b></span>
                    </p>

                    <p>
                        If you have any questions please reach out to us using the live chat option below. Have a great day!
                    </p>
                </>
            );
        }
        else{
            return  <h5>IMPORTANT NOTICE - PLEASE READ</h5>;
        }
    }

    const renderEditorLinkClickIssue = () => {
        return(
            <>
                <p>Technical Issue</p>
                <p>We recently uncovered and fixed a bug related to links in our email editor. </p>
                <p>This issue is strictly related to hyperlinks associated with our calendar widget and form builder.
If you are using these links in your email signature or inside of email/video emails we recommend that you delete the link and add it back.</p>
            </>
        );
    }

    const renderFailedMessage = () => {
        if(
            props.information !== undefined && props.information != null
            && props.information.standard_reg_failed_reason !== ""
        ){
            return (
                <div style={{color: 'red'}}>
                    <p style={{color: 'red'}}>{props.information.standard_reg_failed_reason}</p>
                </div>
            )
        }
        return null;
    }

    const renderTwilioMigration = () => {
        return(
            <>
                {renderFailedMessage()}
                {/* {   (props.information?.current_status == 1) && */}
                <div className="popup-top-section">
                    <p >Your account is now required to register for 10DLC. Standard registration is required for any business 
                        that has 5+ numbers and/or sends more than 3k sms/mms per day.</p>
                    {props.information.count !== 0 &&
                        <p style={{paddingTop: 10}}>Your account currently has {props.information.count} phone numbers.
                        </p>
                    }
                    {/* <p>*** {props.information.reason}</p>     */}
                </div>
                {/* } */}

                
                <div className="popup-mid">
                    <p>
                        Effective <b>March 1</b>, there will be some changes in regards to how businesses send text messages in the United States. These changes are being rolled out by all the major carrier networks like T-Mobile, Sprint, Verizon and others. These changes are designed to increase consumer trust, reduce unwanted spam, and give businesses better deliverability across their network.
                    </p>

                    <p>
                        This industry-wide change is referred to as A2P 10DLC.
                    </p>

                    <p><b>What do I need to do?</b></p>

                    <p>
                        In order to stay compliant with the new guidelines you must register your business by completing a quick survey. Failure to complete registration will result in text message filtering by phone carriers (spam) and additional fees.
                    </p>

                    {/* <p><b>Deadline - March 1, 2022</b></p>

                    <p>
                        In order to avoid a $50 registration fee you must complete the form and register your business prior to March 1. After March 1, the phone carriers will charge this additional fee..
                    </p> */}

                    <p>
                        To learn more 10DLC FAQ’s, please visit- <Link to="//support.twilio.com/hc/en-us/articles/1260800720410" target={'_blank'}><b>What-is-A2P-10DLC ?</b></Link>
                    </p>  
                </div>

                {/* <Link to="/twilio-setup"> */}
                    <div className="pop__up___button" onClick={(e) => goToLinkForReg(e, "/twilio-setup")}>
                        Next {' >>'}
                    </div>   
                {/* </Link>              */}
            </>
        );
    }

    const renderNylasMigration = () => {
        let startTime = "Apr 01 2023 01:00:00",
            endTime = "Apr 02 2023 20:00:00",
            startDateConvert = "",
            endDateConvert = "";

        startDateConvert = window.globalTimezoneConversionToDifferentTimezone( startTime, "UTC", "", "dddd MM/DD/YYYY hh:mm:ss A");
        endDateConvert = window.globalTimezoneConversionToDifferentTimezone( endTime, "UTC", "", "dddd MM/DD/YYYY hh:mm:ss A");

        return(
            <>
                <p><b>Personal email provider Scheduled maintenance is currently in progress. We will provide updates as necessary.</b></p>
                <p>Personal email provider will be performing scheduled maintenance on from <b>{startDateConvert}</b> <i>{Utils.getAccountData("userTimezone")}</i> to <b>{endDateConvert}</b> <i>{Utils.getAccountData("userTimezone")}</i>. During this time, you might face slight issues with connecting new email.</p>
            </>
        );
        /* 
        Saturday 04/01/2023 1AM
        Sunday 04/02/2023 8pm
        */
    }

    const renderView = () => {
        if(props.alertFor === "twilio_migration_alert"){
            return renderTwilioMigration();
        }
        else if(props.alertFor === "link_in_editor_issue"){
            return renderEditorLinkClickIssue();
        }
        else if(props.alertFor === "nylas_migration"){
            return renderNylasMigration();
        }
        return renderAutoRecharge()
    }

	return (
        <div className="important__msg__wr__v2 awesome__scroll_bar">
            {renderView()}
        </div>
	);
};
export default LandingPop;