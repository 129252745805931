import Api from "../../api/InsuranceDashboardApi";
import httpRequest from "../api/httpRequest";



class insuranceDashboardService {
    getDashboardInitialData(params) {
        return httpRequest.get(Api.fetchInitialDashboardData, params);
    }

    getUserLeadCount(params) {
        return httpRequest.get(Api.userLeadCount, params);
    }

    getTeamLeadCount(params) {
        return httpRequest.get(Api.teamLeadCount, params);
    }

    getUserActiveDealCount(params) {
        return httpRequest.get(Api.userActiveDealCount, params);
    }

    getTeamActiveDealCount(params) {
        return httpRequest.get(Api.teamActiveDealCount, params);
    }

    getUserAppointmentCount(params) {
        return httpRequest.get(Api.userAppointmentCount, params);
    }

    getTeamAppointmentCount(params) {
        return httpRequest.get(Api.teamAppointmentCount, params);
    }

    getUserActivity(params) {
        return httpRequest.get(Api.userActivity, params);
    }

    getTeamActivity(params) {
        return httpRequest.get(Api.teamActivity, params);
    }

    getUserStageInfo(params) {
        return httpRequest.get(Api.userUserStageInfo, params);
    }

    getTeamStageInfo(params) {
        return httpRequest.get(Api.teamUserStageInfo, params);
    }

    getUserSaleGoalStats(params) {
        return httpRequest.get(Api.userSaleGoalStats, params);
    }
}

export default new insuranceDashboardService();
