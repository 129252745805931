import { List, ListItem, ListItemText } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserTimelines } from "../../../actions/dashboardAction";
import Utils from "../../../helpers/Utils";
import useDelayCallback from "../../../hooks/useDelayCallback";
import RightListSkeleton from "../skeletons/RightListSkeleton";
import { useIsMounted } from "../../../hooks/IsMounted";
import GlobalSearchBox from "../../globals/GlobalSearchBox";
import { HistoryProvider } from "../../../App";


const InboxPanel = () => {

    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const history = useContext(HistoryProvider);
    const { timelines, rightListLoading } = useSelector(state => state.dashboardReducer);
    const [searchKey,setSearchKey] = useState('');

    useDelayCallback(() => {
        if(isMounted) {
            dispatch(fetchUserTimelines({
                searchKey : searchKey
            }));
        }
    },[searchKey],300);

    useEffect(() => {
        dispatch(fetchUserTimelines({
            searchKey : searchKey
        }));
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const parseMessage = (message) => {
        let tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = message;
        message = (tempDivElement.textContent || tempDivElement.innerText || "").replace(/\s+/g,' ').trim();
        return message.length > 200 ? message.substr(0, 200) + '...' : message;
    }

    const renderTimelines = () => {
        if(rightListLoading)
            return <RightListSkeleton/>

        if(timelines.data !== undefined) {
            return timelines.data.map((timeline,index) => {

                const fullName = timeline.first_name + ' ' + timeline.last_name;
                const redirectUri = `/inbox?contactId=${timeline.contact_id}`;
    
    
                return <ListItem key={index} className={`activity__singleItem ${timeline.Unread ? 'unseenItem' : ''}`}>
                    <ListItemText 
                        primary={
                            <a href={`/contacts/${timeline.contact_id}`} className="inbox__panel_message"
                               onClick={e => {e.preventDefault();history.push(`/contacts/${timeline.contact_id}`)}}>{Utils.getContactDisplayName(fullName,timeline.number,timeline.email)}</a>
                        }
                        secondary={<a  href={redirectUri} 
                            onClick={e => {e.preventDefault();history.push(redirectUri)}} 
                            className="inbox__panel_message">
                                {parseMessage(timeline.latest_message)}
                        </a>}
                    />
                    <span className="activity__hours">
                        {timeline.local_created_at.for_human}
                    </span>
                </ListItem>
            }) 
        }

        return '';
        
    }

    return ( 
        <div className="activity__tabContent awesome__scroll_bar">
            <div>
                <GlobalSearchBox
                    wrapperClass="mr-2 mb-2" 
                    type="search" 
                    value={searchKey} 
                    placeholder="Search Message" 
                    onChange={(e) => setSearchKey(e.target.value)}/>
            </div>
            <List>
                {renderTimelines()} 
            </List>
        </div>
    );
}
 
export default InboxPanel;