import React, { useEffect, useState } from 'react';
import GlobalModal from "../GlobalModal/Modal/GlobalModal";
import LandingPop from "./LandingPop";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { setCookie } from "../../helpers/Cookie";
import Utils from '../../helpers/Utils';
import { closeA2pModalApi, getUserSettingsiDataApi } from '../../api/dashboardApi';
import TenDlcLoading from './10dlc/TenDlcLoading';
import CampaignRegistrationModalView from './campaign-registration-modal-view/CampaignRegistrationModalView';
import EinConfirmation from '../ten-dlc/pages/EinConfirmation';
import VolumnSelectScreen from '../ten-dlc/pages/VolumnSelectScreen';
import SoleProprietorshipCampaignUseCaseRegistration from "./sole-proprietorship-campaign-use-case-registration/SoleProprietorshipCampaignUseCaseRegistration";
import SoleRestriction from "./SoleRestriction";

const _getCookie = (cname) =>  {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export const DashboardAlertPopup = (props) => {

    const [isShowAlert, setIsShowAlert] = useState(false)
	const [isCheckAlert, setIsCheckAlert] = useState(false)
    const [information, setInformation] = useState(null)

    const [warningScreen, setWarningScreen] = useState(false);
    const [soleProprietorshipCampaignUseCaseRegistration, setSoleProprietorshipCampaignUseCaseRegistration] = useState(false);
    const [volumnScreen, setVolumnScreen] = useState(false);
    const [isBackedFromWarning, setIsBackedFromWarning] = useState(false);
    const [force10dlcPopup, setForce10dlcPopup] = useState(false);

    useEffect(()=> {
        try{
            if(props.alertFor === 'credit_auto_recharge_alert'){
                let cookie_value = props.alertFor+'_done'
                let checkCookie = _getCookie(props.alertFor);
                if(checkCookie !== undefined) {
                    if(checkCookie !== cookie_value){
                        if(props.userType === 'owner'){
                            if(Utils.getAccountData('userIsOwner')){
                                try{
                                    getUserSettingsiDataApi().then(res => {
                                        if(res.data.status === 'success'){
                                            setInformation(res.data.data)
                                            if(res.data.data?.credit_reach === 10 || res.data.data?.refile_amount === 10){
                                                setIsShowAlert(true)
                                            }
                                            else{
                                                setIsShowAlert(false)
                                            }
                                        }
                                        else{
                                            setIsShowAlert(false)
                                        }
                                    })
                                    .catch(error=>{
                                        setIsShowAlert(false)
                                        console.log("LandingPop", error)
                                    })
                                }catch(err){
                                    setIsShowAlert(false)
                                }
                            }
                        }
                    }
                }
                else{
                    if(props.userType === 'owner'){
                        if(Utils.getAccountData('userIsOwner')){
                            setIsShowAlert(true)
                        }
                    }
                }
            }
            else if(props.alertFor === 'link_in_editor_issue'){
                let cookie_value = props.alertFor+'_done'
                let checkCookie = _getCookie(props.alertFor);
                if(checkCookie !== undefined) {
                    if(checkCookie !== cookie_value){
                        setIsShowAlert(true)
                    }
                    else{
                        setIsShowAlert(false)
                    }
                }
                else{
                    setIsShowAlert(true)
                }
            }
            else if(props.alertFor === 'twilio_migration_alert'){
                let cookie_value = props.alertFor+'_done'
                let checkCookie = _getCookie(props.alertFor);
                if(checkCookie !== undefined) {
                    if(checkCookie !== cookie_value){
                        try{
                            getUserSettingsiDataApi({'twilio_migration_data': true}).then(res => {
                                let response = res.data;
                                if(response.status === 'success'){
                                    let info = response.data;
                                    if(info.need_to_migration === 1){
                                        setInformation(info)
                                        setIsShowAlert(true)
                                    }
                                    else{
                                        if(info.need_campaign_registration && info.campaign_setting_available !== undefined && !info.campaign_setting_available){
                                            setInformation(info)
                                            setIsShowAlert(true)
                                        }
                                    }
                                    if(info.force_10dlc_popup !== undefined && info.force_10dlc_popup === 1){
                                        setForce10dlcPopup(true)
                                    }
                                    else{
                                        setForce10dlcPopup(false)
                                    }
                                }
                                else{
                                    setIsShowAlert(false)
                                }                                
                            })
                            .catch(error=>{
                                setIsShowAlert(false)
                                console.log("LandingPop", error)
                            })
                        }catch(err){
                            setIsShowAlert(false)
                        }
                    }
                }
                else{
                    try{
                        getUserSettingsiDataApi({'twilio_migration_data': true}).then(res => {
                            let response = res.data;
                            if(response.status === 'success'){
                                let info = response.data;
                                if(info.need_to_migration === 1){
                                    setInformation(info)
                                    setIsShowAlert(true)
                                }
                                else{
                                    if(info.need_campaign_registration && info.campaign_setting_available !== undefined && !info.campaign_setting_available){
                                        setInformation(info)
                                        setIsShowAlert(true)
                                    }
                                }
                                if(info.force_10dlc_popup !== undefined && info.force_10dlc_popup === 1){
                                    setForce10dlcPopup(true)
                                }
                                else{
                                    setForce10dlcPopup(false)
                                }
                            }
                            else{
                                setIsShowAlert(false)
                            }
                        })
                        .catch(error=>{
                            setIsShowAlert(false)
                            console.log("LandingPop", error)
                        })
                    }catch(err){
                        setIsShowAlert(false)
                    }
                }
            }
            else if(props.alertFor === 'twilio_migration_processing'){
                setIsShowAlert(true)
            }
            else if(props.alertFor === 'twilio_migration_campaign_registration'){
                setIsShowAlert(true)
            }
            else if(props.alertFor === 'campaign_registration_success'){
                setIsShowAlert(true)
            }
            else if(props.alertFor === 'nylas_migration'){
                let cookie_value = props.alertFor+'_done'
                let checkCookie = _getCookie(props.alertFor);
                if(checkCookie !== undefined) {
                    if(checkCookie !== cookie_value){
                        setIsShowAlert(true)
                    }
                    else{
                        setIsShowAlert(false)
                    }
                }
                else{
                    setIsShowAlert(true)
                }
            }
        }catch(err){
            setIsShowAlert(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(props.alertFor === 'twilio_migration_campaign_registration'){
            setIsShowAlert(true)
        }else if(props.alertFor === 'show-ein-confirmation'){
            setIsShowAlert(true)
        }else if(props.alertFor === 'show-volume-select-screen'){
            setIsShowAlert(true)
        }
    },[props.alertFor])

    const closeA2pModalFunction = () =>{
        try{
            closeA2pModalApi().then()
            .catch(error=>{
                
                console.log("a2pclose", error)
            })
        }catch(err){
            console.log('close error :',[err])
            setIsShowAlert(false)
        }
    }

    const handleOnCloseAlertModal = () => {

		setIsShowAlert(false)
        if(props.alertFor === 'twilio_migration_processing'){
            props.hanldeClose({onlyClose: true, redirect: false})
            return
        }
        if(props.alertFor === 'twilio_migration_campaign_registration' || 
            props.alertFor === "show-ein-confirmation" || 
            props.alertFor === "show-volume-select-screen"
            ){
            props.setAlertFor('twilio_migration_alert')
            return
        }
        closeA2pModalFunction()

		if(isCheckAlert){
            let cookie_value = props.alertFor+'_done'
			setCookie(props.alertFor, cookie_value, '365 day')
		}

	}

    if(!isShowAlert) return null;

    let modal_props = {};
    if(information != null && information.need_to_migration === 1 && information.policyType == 1 && !warningScreen){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeader'] = undefined;
    }
    if(information != null && information.need_to_migration === 1 && information.policyType == 1 && warningScreen){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeader'] = undefined;
    }
    if(props.alertFor === 'twilio_migration_processing' || props.alertFor === 'campaign_registration_success'){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeader'] = true;
    }
    if (props.alertFor === 'twilio_migration_campaign_registration' || 
        props.alertFor === 'show-ein-confirmation' ||
        props.alertFor === 'show-volume-select-screen'){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeader'] = undefined;
    }
    if(props.alertFor === 'twilio_migration_alert' && (information != null && information.need_to_migration === 0)){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeader'] = undefined;
    }
    if(force10dlcPopup){
        modal_props['stopBackDrop'] = true;
        modal_props['hideFooter'] = true;
        modal_props['hideHeaderCloseButton'] = true;
    }
    if(force10dlcPopup && warningScreen){
        modal_props['hideHeader'] = true;
    }
    if(soleProprietorshipCampaignUseCaseRegistration && (information && information.count === 1)){
        modal_props['hideHeader'] = undefined;

    }

    if(props.alertFor === 'nylas_migration'){
        modal_props['extraInFooter'] = ()=>{
            return (
            <div style={{width: 'auto', display:'flex', flexDirection: 'row', gap: '5px', alignItems:'center', justifyContent:'left'}}>
                <span style={{cursor: 'pointer', display:'flex'}} onClick={() => setIsCheckAlert(!isCheckAlert)}>{isCheckAlert ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</span>
                <span>Don't show it again</span>
            </div>
            )
        };
        modal_props['extraInFooterStyle'] = {
            marginRight: 'auto',
            paddingLeft: '20px'
        }
    }

    const handleWarningScreen = () => {
        // if (Utils.getAccountData("centralBilling")){
        //     setWarningScreen(true);
        // }else {
            setSoleProprietorshipCampaignUseCaseRegistration(true);
        // }
    }


    const renderView = () => {

        if((soleProprietorshipCampaignUseCaseRegistration && (information && (information.count === 1 || information.count === 0)))
        || (props.alertFor === "show-ein-confirmation" && soleProprietorshipCampaignUseCaseRegistration)){
            return <SoleProprietorshipCampaignUseCaseRegistration
                handleClose={handleOnCloseAlertModal}
                data={information}
                setSoleProprietorshipCampaignUseCaseRegistration={setSoleProprietorshipCampaignUseCaseRegistration}
                getUserNotification={props.getUserNotification}
            />;
        } else if(soleProprietorshipCampaignUseCaseRegistration && (information && information.count > 1)){
            return <SoleRestriction handleBack={()=>{
                setIsBackedFromWarning(true);
                setSoleProprietorshipCampaignUseCaseRegistration(false);
            }} information={information} />;
        }else if((information != null && information.need_to_migration === 1 && information.policyType == 1 && !volumnScreen) || isBackedFromWarning || (props.alertFor === "show-ein-confirmation" && !volumnScreen)){
            return (
                <EinConfirmation
                    campaignRegistration={props}
                    warningScreen={warningScreen} 
                    handleClick={handleWarningScreen}
                    information={information} 
                    handleShowVolumnScreen = {() => {
                        setVolumnScreen(true)

                        if (isBackedFromWarning){
                            setIsBackedFromWarning(false);
                        }
                    }}
                    force10dlcPopup={force10dlcPopup}
                />
            )
        }
        else if((information != null && information.need_to_migration === 1 && information.policyType == 1 && volumnScreen)
            || props.alertFor === "show-volume-select-screen" || (volumnScreen && props.alertFor === "show-ein-confirmation")){
                return (
                <VolumnSelectScreen 
                    information={information}
                    force10dlcPopup={force10dlcPopup}
                />
            )
        }
        /* for edit failed registration */
        else if(information != null && information.need_to_migration === 0){
            return <CampaignRegistrationModalView handleClose={handleOnCloseAlertModal} data={information} 
            getUserNotification={props.getUserNotification}/>
        }

        if(props.alertFor === "twilio_migration_processing"){
            return <TenDlcLoading {...props} />;
        }
        else if(props.alertFor === "twilio_migration_campaign_registration"){
            return <CampaignRegistrationModalView {...props} handleClose={handleOnCloseAlertModal} getUserNotification={props.getUserNotification}/>
        }
         else if(props.alertFor === "campaign_registration_success"){
            return <TenDlcLoading screen="campaign_registration" {...props} />
        }
        else {
            return <LandingPop alertFor={props.alertFor} information={information} handleClosePopup={handleOnCloseAlertModal} />;
        }
    }
    const renderTitle = () => {
        if(information != null && information.need_to_migration === 1 && information.policyType == 1 && warningScreen){
            return (<span style={{color: 'red', fontSize: '20px', fontWeight: 500}}>Warning !</span>)
        }
        if(props.alertFor === 'nylas_migration'){
            return "Attention! Schedule Maintenance";
        }
        return "New Texting Regulations - Action Required";
    }
    return <GlobalModal
            open={isShowAlert}
            onClose={handleOnCloseAlertModal}
            className={`global-small-modal global-xs-modal global__modal__center dashboard__user__alert 
                ${(props.alertFor === 'twilio_migration_campaign_registration' || 
                (soleProprietorshipCampaignUseCaseRegistration && ((information && information.count <= 1) || props.alertFor === "show-ein-confirmation"))) ? 'dua_campaign_registration' : ''} ${(props.alertFor === 'twilio_migration_alert' && information != null && information.need_to_migration === 0) ? 'dua_campaign_registration' : ''}`}
            title={renderTitle()}
            {...modal_props}
        >
            {
                renderView()
            }
        </GlobalModal>
};
