import { useHistory } from 'react-router-dom';
import { TWILIO_SETUP } from '../../../constants/CoreConstants';
import Styles from '../styles.module.css'

const VolumnSelectScreen = (props) => {

    const history = useHistory();
    const goToLinkForReg = (e, link, type) => {
        e.preventDefault();
        history.push({
            pathname: link,
            state: { from: "dashboard_alert_popup", data: props.information, policyType: type}
        });
        if(props.handleClosePopup !== undefined){
            props.handleClosePopup();
        }
    }

    return (
        <div className={Styles.tdp_ein_volumn_select_wrp}>
            <div className={Styles.tdp_ein_vsw_header}>
                <span className={Styles.tdp_ein_vswh_title}>A2P/10DLC Registration</span>
            </div>
            <div className={Styles.tdp_ein_vsw_body}>
                <span className={Styles.tdp_ein_vswb_title}>On average do you plan to send more or less than 6,000 messages per day?</span>
                <div className={Styles.tdp_ein_vswb_button_wrp}>
                    <span className={Styles.tdp_ein_vswbbw_button} onClick={(e) => goToLinkForReg(e, '/twilio-setup', TWILIO_SETUP.policyTypeHighVolumn)}>More than 6,000/day</span>
                    <span className={Styles.tdp_ein_vswbbw_button} onClick={(e) => goToLinkForReg(e, '/twilio-setup', TWILIO_SETUP.policyTypeLowVolumn)}>Less than 6,000/day</span>
                </div>
            </div>
        </div>
    );
}
export default VolumnSelectScreen;