import React from "react";
import ProgressCircle from "../common/progress/Progress";
import GoalItem from "./GoalItem";
import { TrophyIcon } from "../common/Icons";
import DashBoardBasicSelect from "../common/DashBoardBasicSelect";
import { styled } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";
import {fetchUserSalesGoalStart} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import {GOAL_TYPE_SALES} from "../../../constants/CoreConstants";

const GoalChartWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",

  "& .userSelect": {
    width: 300,
    marginLeft: 'auto !important',
    marginRight: '14px !important',
  }
}));

const GoalChart = () => {
    const dispatch = useDispatch();
    const {
        startedTodayAt,
        goalForToday,
        achievedToday,
        stillNeededForToday,
        goalList,
        userSaleGoalId
    } = useSelector(selectInsuranceDashboardState);
  return (
    <GoalChartWrapper className="goalChart">
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <DashBoardBasicSelect
          name="pipeline"
          options={goalList}
          value={userSaleGoalId}
          onChange={(e) => { dispatch(fetchUserSalesGoalStart({ userSaleGoalId: e.target.value, goalStatFilter: GOAL_TYPE_SALES })) }}
          mapping={{
            label: "name",
            value: "id",
          }}
          className={"userSelect"}
        />

        <ProgressCircle
            data={{
                goalForToday: goalForToday,
                achievedToday: achievedToday,
                stillNeededForToday: stillNeededForToday,
            }}
        />
      </div>

      <GoalItem
        text="STARTED TODAY AT"
        value={Utils.formatCurrency(Utils.toZero(startedTodayAt), "$")}
        icon={<TrophyIcon />}
        bgColor={"#FFFAF2"}
      />
    </GoalChartWrapper>
  );
};

export default GoalChart;
