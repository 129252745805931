import {sendRequest} from "../../api/rootApi";
import Api from "./Api";

export const getUserInfo = async (payload) => {
    return sendRequest("post", Api.getUserInfo, payload);
};

export const connectNylas = async (payload) => {
    return sendRequest("post", Api.connectNylas, payload);
};

export const userConnectedEmails = async () => {
  return sendRequest("post", Api.userConnectedEmails);
};

export const inactiveUserConnectedEmail = async (payload) => {
  return sendRequest("post", Api.inactiveUserConnectedEmail, payload);
};