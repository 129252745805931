import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import * as ACTION from '../constants/dashboardActionTypes'
import * as DASHBOARD_API from "../api/dashboardApi";


function* dashboardWatcher() {
    yield takeEvery(ACTION.FETCH_DASHBOARD_COUNT, fetchDashboardCount);
    yield takeEvery(ACTION.FETCH_SINGLE_COUNT, fetchSingleCount);
    yield takeEvery(ACTION.FETCH_PIPELINE_SOURCES, fetchPipelineSources);
    yield takeEvery(ACTION.FETCH_CHART_DATA, fetchChartData);
    yield takeEvery(ACTION.FETCH_USER_ACTIVITIES, fetchUserActivities);
    yield takeEvery(ACTION.FETCH_USER_TIMELINES, fetchUserTimelines);
    yield takeEvery(ACTION.FETCH_USER_NOTIFICATIONS, fetchUserNotifications);
}

function* fetchDashboardCount(action) {
    const { payload } = action;
    try {
        localStorage.setItem('dashboardFilterUser',payload.filterUser);
        yield put({type: ACTION.STORE_DASHBOARD_FILTERS, payload: payload});
        const response = yield call(DASHBOARD_API.getDashboardCount, payload);
        yield put({type: ACTION.STORE_DASHBOARD_COUNT, payload: response.data.data});

        if(payload.initCall !== true) {
            //If its call from 
            const { selectedPiePipeline, selectedFunnelPipeline } = yield select(state => state.dashboardReducer);
            //Fetch chart data
            if(selectedFunnelPipeline) {
                payload['funnelPipeline'] = selectedFunnelPipeline;
            }
            if(selectedPiePipeline) {
                payload['piePipeline'] = selectedPiePipeline;
            }

            const chartResponse = yield call(DASHBOARD_API.getChartData, payload);
            yield put({type: ACTION.STORE_FUNNEL_DATA, payload: chartResponse.data.data});
            yield put({type: ACTION.STORE_PIE_DATA, payload: chartResponse.data.data});
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}   

function* fetchSingleCount(action) {
    const { payload } = action;
    try {
        const response = yield call(DASHBOARD_API.getDashboardCount, payload);

        if (payload.box === 'count') {
            yield put({type: ACTION.STORE_DEAL_COUNT, payload: response.data.data});
        } else if (payload.box === 'value') {
            yield put({type: ACTION.STORE_DEAL_VALUE, payload: response.data.data});
        } else if (payload.box === 'rate') {
            yield put({type: ACTION.STORE_DEAL_CONVERSION, payload: response.data.data});
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchPipelineSources(action) {
    const { payload } = action;
    try {
        const response = yield call(DASHBOARD_API.getPipelineSources, payload);

        let data = response.data.data;
        let selectedFunnelPipeline = data.funnelPipelines.length > 0 ? data.funnelPipelines[0].id : null;
        let selectedPiePipeline = data.piePipelines.length > 0 ? data.piePipelines[0].id : null;

        yield put({type: ACTION.STORE_PIPELINE_SOURCES, 
            payload: {
                ...data,
                selectedFunnelPipeline:selectedFunnelPipeline,
                selectedPiePipeline : selectedPiePipeline
            }});

        //Fetch chart data
        let selectData = {};
        if(selectedFunnelPipeline) {
            selectData['funnelPipeline'] = selectedFunnelPipeline;
        }
        if(selectedPiePipeline) {
            selectData['piePipeline'] = selectedPiePipeline;
        }

        selectData['startDate'] = payload.startDate;
        selectData['endDate'] = payload.endDate;
        selectData['filterUser'] = payload.filterUser;

        const chartResponse = yield call(DASHBOARD_API.getChartData, selectData);
        yield put({type: ACTION.STORE_FUNNEL_DATA, payload: chartResponse.data.data});
        yield put({type: ACTION.STORE_PIE_DATA, payload: chartResponse.data.data});

    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}


function* fetchChartData(action) {
    const { payload } = action;
    try {
        if (payload.box === 'funnel') {
            yield put({type: ACTION.STORE_FUNNEL_PIPELINE_ID, payload: {pipeline : payload.pipeline}});
            const response = yield call(DASHBOARD_API.getChartData, payload);
            yield put({type: ACTION.STORE_FUNNEL_DATA, payload: response.data.data});
         } else if (payload.box === 'pie') {
            yield put({type: ACTION.STORE_PIE_PIPELINE_ID, payload: {pipeline : payload.pipeline}});
            const response = yield call(DASHBOARD_API.getChartData, payload);
            yield put({type: ACTION.STORE_PIE_DATA, payload: response.data.data});
         }
        
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchUserActivities(action) {
    const { payload } = action;
    try {
        yield put({type: ACTION.SET_RIGHT_SIDE_LOADER, payload: {rightListLoading : true}});
        const response = yield call(DASHBOARD_API.getUserActivites, payload);
        yield put({type: ACTION.STORE_USER_ACTIVITIES, payload: response.data.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchUserTimelines(action) {
    const { payload } = action;
    try {
        yield put({type: ACTION.SET_RIGHT_SIDE_LOADER, payload: {rightListLoading : true}});
        const response = yield call(DASHBOARD_API.getUserTimelines, payload);
        yield put({type: ACTION.STORE_USER_TIMELINES, payload: response.data.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchUserNotifications(action) {
    const { payload } = action;
    try {
        yield put({type: ACTION.SET_RIGHT_SIDE_LOADER, payload: {rightListLoading : true}});
        const response = yield call(DASHBOARD_API.getUserNotifications, payload);
        yield put({type: ACTION.STORE_USER_NOTIFICATIONS, payload: response.data.data});
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}


export default function* dashboardMiddleware() {
    yield all([
        dashboardWatcher()
    ])
}