import { IconButton, styled } from "@material-ui/core";
import React from "react";
import Title from "../common/Title";
import {CalenderIcon} from "../common/Icons";
import DashBoardBasicSelect from "../common/DashBoardBasicSelect";
import {
  ACTIVITY_TYPE_APPOINTMENT,
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_DEAL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_TASK,
  DASHBOARD_ACTIVITY_LIST,
  DATEPICKER_INITIAL_SETTING,
  FILTER_TYPE_TEAM,
  FILTER_TYPE_USER
} from "../../../constants/CoreConstants";
import {useDispatch, useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import Utils from "../../../helpers/Utils";
import {fetchActivityStart} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import UserChip from "../common/UserChip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Call from "./Call";
import Email from "./Email";
import Deal from "./Deal";
import Appointment from "./Appointment";
import Task from "./Task";
import ActivitySkeleton from "../skeleton/ActivitySkeleton";

const ActivityWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,

  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },

  "& .userSelect": {
    // width: 250,
    // marginLeft: 'auto !important',
    marginRight: "14px !important",
  },

  "& .content": {
    "& .filter": {
      display: "flex",
      gap: "24px",
      marginBottom: 24,
    },

    "& .filterByActivity": {
      flex: 7,
    },
    "& .filterByDate": {
      flex: 5,
    },
  },
}));

export const EachActivityWrapper = styled("div")(({ theme, bgColor = "#ECF4FF" }) => ({
  width: "100%",
  minHeight: 148,

  padding: 8,
  backgroundColor: bgColor,
  borderRadius: 12,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 24,

  "& .GoalText": {
    padding: 18,

    "& .subTitle": {
      marginBottom: 4,
    },
  },

  "& .goalIcon": {
    height: 48,
    width: 48,
    backgroundColor: "white",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "1px solid #DEE0E3",
    borderRadius: 12,
  },
}));

const Activity = () => {
  const dispatch = useDispatch();
  const { loadingActivityData, selectedActivity, activityFilterType, activityDateFilterLabel, activityStartDate, activityEndDate, activity } = useSelector(selectInsuranceDashboardState);

  const handleDateCallback = (start, end, label) => {
    let utcStartDate = start.format('YYYY-MM-DD');
    let utcEndDate = end.format('YYYY-MM-DD');

    let startDate = window.globalTimezoneConversionToDifferentTimezone(utcStartDate + ' 00:00:00', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss');
    let endDate = window.globalTimezoneConversionToDifferentTimezone(utcEndDate + ' 23:59:59', Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss');
    dispatch(fetchActivityStart({
      activityStartDate: startDate,
      activityEndDate: endDate,
      activityFilterType: activityFilterType,
      selectedActivity: selectedActivity,
      activityDateFilterLabel: label
    }));
  }

  const renderActivity = () => {
    switch (selectedActivity){
      case ACTIVITY_TYPE_CALL:
        return <Call activity={activity}/>;
      case ACTIVITY_TYPE_EMAIL:
        return <Email activity={activity}/>;
      case ACTIVITY_TYPE_DEAL:
        return <Deal activity={activity}/>;
      case ACTIVITY_TYPE_APPOINTMENT:
        return <Appointment activity={activity}/>;
      case ACTIVITY_TYPE_TASK:
        return <Task activity={activity}/>;
    }
  };

  return (
    <ActivityWrapper>
      <div className="header">
        <Title text="Activity" />

        <div className="actions">
          <UserChip filterUser={activityFilterType} onClickAction={() =>
              dispatch(fetchActivityStart({
                    activityStartDate: activityStartDate,
                    activityEndDate: activityEndDate,
                    activityFilterType: activityFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM,
                    selectedActivity: selectedActivity,
                    activityDateFilterLabel: activityDateFilterLabel
                  })
              )}/>

          <div>
            <DateRangePicker
                initialSettings={{
                  ...DATEPICKER_INITIAL_SETTING,
                  startDate: moment('09/01/2020'),
                  endDate: moment(),
                }}
                onCallback={handleDateCallback}
            >
              <IconButton size="small">
                <CalenderIcon/>
              </IconButton>
            </DateRangePicker>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="filter">
          <DashBoardBasicSelect
              name="pipeline"
              options={DASHBOARD_ACTIVITY_LIST}
              value={selectedActivity}
              onChange={(e) => {
                dispatch(fetchActivityStart({
                  activityStartDate: activityStartDate,
                  activityEndDate: activityEndDate,
                  activityFilterType: activityFilterType,
                  selectedActivity: e.target.value,
                  activityDateFilterLabel: activityDateFilterLabel
              }));
            }}
            mapping={{
              label: "label",
              value: "value",
            }}
            className={"userSelect filterByActivity"}
            fullWidth
          />
        </div>

        { loadingActivityData ? <ActivitySkeleton/> : renderActivity()}

      </div>
    </ActivityWrapper>
  );
};

export default Activity;
