import Styles from './style.module.css';
const MessageBox = ({message='', reason=''}) => {
    return (
        <div className={Styles.tdpMbWrp}>
            <span className={Styles.tdpMwMessage}>{message}</span>
            {reason !== "" &&
            <span className={Styles.tdpMwReason}>Reason: {reason}</span>
            }
        </div>
    );
}
export default MessageBox;