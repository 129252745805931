import React, { useState } from 'react';
import MEISCustomSwitch from '../common/CampaignListSwitch/CampaignListSwitch';
import CustomMenuPype from '../common/custom-menu/CustomMenuPype';
import GlobalModal from '../GlobalModal/Modal/GlobalModal';
import './dashboard-low-credit.css';

const ArrowDownIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M7 10l5 5 5-5H7z"/>
        </svg>
    );
}

const CreditAlert = (props) => {
    const [isShowAlert, setIsShowAlert] = useState(true);
    const [screen, setScreen] = useState('low_credit');
    
    const handleOnCloseAlertModal=()=>{
        setIsShowAlert(false);
    }
    let modal_props = {};
    // modal_props['stopBackDrop'] = true;
    modal_props['hideFooter'] = true;
    modal_props['hideHeader'] = true;
    return (
        <GlobalModal
            open={isShowAlert}
            onClose={handleOnCloseAlertModal}
            className={'global-xs-modal global__modal__center dashboard__user__alert'}
            title={"Low Credit Alerts"}
            {...modal_props}
        >
            {screen === 'low_credit' ?
            <LowCreditScreen handleChangeScreen={() => setScreen('auto_recharge')} {...props} />
            :
            <AutoRechargeScreen />
            }
        </GlobalModal>
  )
}
export default CreditAlert;

const LowCreditScreen = (props) => {
    return (
        <div className='dashboard_low_credit_alert_wrapper'>
            <div className='dlcaw_image_wrapper'>
                <img className='dlcaw_iw_image' src={process.env.REACT_APP_CDN_LINK+'asset/image/releases/1.0.0/credit-low.webp'} alt='low credit'/>
            </div>
            <div className='dlcaw_right_side_wrapper'>
                <span className='dlcaw_rsw_header_text'>Oops! Your Funds Are Running Low.</span>
                <div className='dlcaw_rsw_des_text'>
                    <p>Please make a deposit. If you run out of funds your campaigns will pause and you'll be unable to send and receive new messages.</p>
                </div>
                <div className='dlcaw_action_wrapper'>
                    <span className='dlcaw_aw_button' onClick={()=>props.reRoute('/billing')} >Make a Deposit</span>
                    {/* <span className='dlcaw_aw_button' onClick={props.handleChangeScreen} >Make a Deposit</span> */}
                </div>
                <span className='dlcaw_rsw_hints'>*To avoid running out of funds in the future be sure to enable auto recharge</span>
            </div>
        </div>
    );
}
const AutoRechargeScreen = (props) => {
    const [status, setStatus] = useState(true);
    const [info, setInfo] = useState({
        reaches: 10,
        amout: 10
    });
    /* get user data by api and update status */
    
    return (
        <div className='dashboard_auto_recharge_wrapper'>
            <span className='darw_header_text'>
                Turn on auto recharge below so you never run out of credits (Recommended)
            </span>
            <div className='darw_on_off_wrapper'>
                <span className='darw_ofw_text'>Auto Recharge</span>
                <div className='darw_ofw_switch_wrapper'>
                    <MEISCustomSwitch
                        onChange={(value) => {
                            setStatus(value)
                            /* need to call api */
                        }}
                        value={status}
                        off={{
                            text: 'OFF'
                        }}
                        on={{
                            text: 'ON'
                        }}
                        width= '70px'
                    />
                </div>
            </div>
            <div className='darw_options_wrapper'>
                <div>
                    <span>When my balance reaches</span>
                    <CustomMenuPype
                        wrapperClass="darw_ow_option_reaches"
                        popup= {{
                            placement: 'center',
                            list: [
                                {label: '$10', value: 10},
                                {label: '$20', value: 20},
                                {label: '$30', value: 30},
                                {label: '$50', value: 50},
                                {label: '$100', value: 100},
                            ],
                            onClick: (item) => {
                                setInfo({
                                    ...info,
                                    reaches: item.value
                                })
                            },
                            height: 'auto',
                            width: '80px',
                            listStyle: {
                                justifyContent: 'center'
                            }
                        }}
                        label= {{
                            renderCustom: (
                                <div className="darw_ow_or_label_wrapper">
                                    <span className="darw_ow_or_lw_label">${info.reaches}</span>
                                    <span className="darw_ow_or_lw_icon"><ArrowDownIcon /></span>
                                </div>
                            )
                        }}
                    />
                </div>
                <div>
                    <span>Automatically recharge this amount</span>
                    <CustomMenuPype
                        wrapperClass="darw_ow_option_amount"
                        popup= {{
                            placement: 'center',
                            list: [
                                {label: '$10', value: 10},
                                {label: '$20', value: 20},
                                {label: '$30', value: 30},
                                {label: '$50', value: 50},
                                {label: '$100', value: 100},
                            ],
                            onClick: (item) => {
                                setInfo({
                                    ...info,
                                    amout: item.value
                                })
                            },
                            height: 'auto',
                            width: '80px',
                            listStyle: {
                                justifyContent: 'center'
                            }
                        }}
                        label= {{
                            renderCustom: (
                                <div className="darw_ow_oa_label_wrapper">
                                    <span className="darw_ow_oa_lw_label">${info.amout}</span>
                                    <span className="darw_ow_oa_lw_icon"><ArrowDownIcon /></span>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            <div className='darw_save_action_wrapper'>
                <span className='darw_saw_button'>Save auto recharge setting</span>
            </div>
        </div>
    );
}