// moved to cdn
// import '../dashboard.css'
import TwilioMigrationSteps from "./TwilioMigrationSteps";

const TwilioMigrationPopup = (props) => {

    return (
        <TwilioMigrationSteps />
    )
}
export default TwilioMigrationPopup;