import { Skeleton } from "@material-ui/lab";

const BasicSkeleton = (props) => {
    const width = 60;
    return ( 
        props.loading ? 
        <Skeleton
        animation={'wave'}
         width={width}/> : props.content
     );
}
 
export default BasicSkeleton;