import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";
import { useState, useRef, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNotifications } from "../../../actions/dashboardAction";
import { HistoryProvider } from "../../../App";
import Utils from "../../../helpers/Utils";
import RightListSkeleton from "../skeletons/RightListSkeleton";

const perPage = 20;

const NotificationsPanel = () => {

    const dispatch = useDispatch();
    const history = useContext(HistoryProvider);
    const { notifications, rightListLoading } = useSelector(state => state.dashboardReducer);

    /* For Scrolling */
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMoreActivity, setHasMoreActivity] = useState(false);

    const scrollRef = useRef();

    useEffect(() => {
        loadNotifications();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadNotifications = () => {
        dispatch(fetchUserNotifications({current_page: currentPage, per_page: perPage}, (callback) => {
            if(callback.data.notifications.data.length >= 20) {
                setHasMoreActivity(true);
                setCurrentPage(currentPage + 1);
            } else {
                setHasMoreActivity(false);
            }
            setLoadingMore(false);
        }));
    }

    // const handleScrollToTop = () => {
    //     if (scrollRef.current) {
    //         scrollRef.current.scrollTo(0, 0);
    //     }
    // }


    const renderNotifications = () => {
        if(rightListLoading)
            return <RightListSkeleton/>

        if(notifications) {
            let notificationItems = []; 
            
            notifications.forEach((notification,index) => {

                const message = notification.message.replace(/<[^>]+>/g, '');
                const fullName = notification.contact ? notification.contact.first_name + ' ' + notification.contact.last_name : '';

                notificationItems.push(
                    <a href={'/contacts/'+notification.contact.id} onClick={e => {e.preventDefault();history.push('/contacts/'+notification.contact.id)}}>
                        <ListItem key={index} className={`activity__singleItem ${notification.seen ? '' : 'unseenItem'}`}>
                            <ListItemAvatar>
                                <Avatar>
                                    {Utils.getInitial(fullName)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText 
                                primary={<>
                                {/* <div className="topbar__message">Got new Message from</div> */}
                                <Tooltip title={Utils.getContactDisplayName(fullName,notification.contact.number,notification.contact.email,true)} placement="top-start">
                                    <span>{Utils.getContactDisplayName(fullName,notification.contact.number,notification.contact.email)}</span>
                                </Tooltip>
                                {
                                    notification.seen !== 1 && <span className="badge">New</span>  
                                }
                                </>}
                                secondary={message.length > 40 ? message.substr(0, 38)+' ...' : message}
                            />
                            <span className="activity__hours">{notification.local_created_at.for_human}</span>
                        </ListItem>
                    </a>
                )
            })
            return notificationItems;
        }

        return <p className="d-flex justify-content-center">NO MORE NOTIFICATIONS</p>;
    }

    const loadMoreNotifications = (e) => {
        if(!loadingMore && hasMoreActivity && Math.round((e.target.scrollTop + e.target.clientHeight), 10) >= Math.round(e.target.scrollHeight, 10)) {
            setLoadingMore(true);
            loadNotifications();
        }
    }

    return ( 
        <div className="activity__tabContent awesome__scroll_bar" onScroll={loadMoreNotifications} ref={scrollRef}>
            <List>
                {renderNotifications()}
            </List>

            { //! Don't remove this code will design letter
                /*
                <div className="activity__back__to__top__wr">
                    {
                        userActivities.length > 0 && !loadingMore && !hasMoreActivity && (
                            <div onClick={handleScrollToTop} className="activity__back__to__top">
                                <span>Back To Top</span>
                                {IconList.backToTop}
                            </div>
                        )
                    }
                </div> */}

            {
                loadingMore && (
                    <div className="load_more_activities">Loading more...</div>
                )
            }
        </div>
     );
}
 
export default NotificationsPanel;