import React from "react";
import { ArrowRightIcon } from "../common/Icons";
import Title from "../common/Title";
import CardSectionTab from "./CardSectionTab";
import { CardWrapper, GoButton } from "./index";
import UserChip from "../common/UserChip";
import {
    APPOINTMENT_SCHEDULE_PAST,
    APPOINTMENT_SCHEDULE_TODAY, APPOINTMENT_SCHEDULE_UPCOMING,
    FILTER_TYPE_TEAM,
    FILTER_TYPE_USER
} from "../../../constants/CoreConstants";
import {
    fetchAppointmentCountStart,
} from "../../../reducers/Insurance-dashboard/insuranceDashboardSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectInsuranceDashboardState} from "../../../reducers/Insurance-dashboard/insuranceDashboardSelector";
import InsuranceCommonSkeleton from "../skeleton/InsuranceCommonSkeleton";

const TaskCard = ({ goToLink }) => {
   const dispatch = useDispatch();
   const { loadingAppointmentCount, totalAppointmentCountFilterType, totalAppointmentCount, appointmentSchedule } = useSelector(selectInsuranceDashboardState);

  return (
    <CardWrapper className="TaskCard">
      <div>
          <div className="header">
              <Title text={"Task/Appointments"} className="title"/>

              <div className="actions">
                  <UserChip filterUser={totalAppointmentCountFilterType} onClickAction={() => dispatch(fetchAppointmentCountStart({ schedule: appointmentSchedule, userFilter: totalAppointmentCountFilterType === FILTER_TYPE_TEAM ? FILTER_TYPE_USER : FILTER_TYPE_TEAM }))}/>
              </div>
          </div>
      </div>

        {
            loadingAppointmentCount ?
                <InsuranceCommonSkeleton/> :
                <div className="body">
                    <div>
                        <Title text={totalAppointmentCount} className="value" style={{marginBottom: 4}}/>
                        <CardSectionTab
                            list={[
                                {id: APPOINTMENT_SCHEDULE_PAST, title: "Past Due"},
                                {id: APPOINTMENT_SCHEDULE_TODAY, title: "Today"},
                                {id: APPOINTMENT_SCHEDULE_UPCOMING, title: "Upcoming"},
                            ]}
                            activeTab={appointmentSchedule}
                            onActiveTab={(id) => {
                                dispatch(fetchAppointmentCountStart({
                                    schedule: id,
                                    userFilter: totalAppointmentCountFilterType
                                }))
                            }}
                            className="taskTab"
                        />
                    </div>
                    <GoButton
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowRightIcon className="goIcon"/>}
                        fullWidth
                        onClick={() => goToLink('/activity-calendar', null, '?tab=list')}
                    >
                        Go
                    </GoButton>
                </div>
        }

    </CardWrapper>
  );
};

export default TaskCard;
