import React from 'react'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import './style.css'

// const loadingGifImg = `${process.env.REACT_APP_CDN_LINK}/asset/images/releases/1.0.0/loadr.gif`
const loadingGifImg = `https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/loadr.gif`

const TenDlcLoading = (props) => {

    if(props.screen !== undefined && props.screen === 'campaign_registration' ){
        return(
            <div className='processing_modal_wrapper'>
                <div className='processing_modal_container'>
                   
                        {/* <div className='processing_modal_check_btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M13.833 28.125 20 21.958l6.167 6.167 1.958-1.958L21.958 20l6.167-6.167-1.958-1.958L20 18.042l-6.167-6.167-1.958 1.958L18.042 20l-6.167 6.167ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Z"/></svg>
                        </div> */}
                        <div className='processing_modal_container_v2_thumb'>
                            <ThumbUpIcon/>
                        </div>

                        <div className='processing_modal_container_v2_update'>
                            <h4 className='processing_modal_container_v2_title'>You're all done !</h4>
                            <h4 className='processing_modal_container_v2_title'>Thank you for completing your registration</h4>
                        </div>
                        <div className='processing_modal_go_step_btn processing_modal_go_step_btn_update' onClick={() => props.handleClose({onlyClose: true, redirect: true})}>Close Window</div>
                    </div>
               
            </div>
        )
    }

    const renderMessage = () => {
        if(props.response !== undefined && props.response != null){
            if(props.response.campaign_setting_available !== undefined && props.response.campaign_setting_available){
                return <div className='processing_modal_go_step_btn' onClick={() => props.handleClose({onlyClose: true, redirect: true})}>Close the window</div>
            }
        }
        return <div className='processing_modal_go_step_btn' onClick={() => props.handleClose({onlyClose: true, redirect: true})}>Go to next step {'>'} </div>
    }

    return(
        <div className='processing_modal_wrapper'>
            <div className='processing_modal_container'>
            {props.hasSuccess !== '' || props.hasError !== '' ?
                <div>
                    <div className='processing_modal_check_btn'>
                        {props.hasSuccess !== '' ?
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.55 19.91a1.72 1.72 0 0 1-1.19-.49l-6.2-6.21a.74.74 0 0 1 0-1.06l2.41-2.42a1.73 1.73 0 0 1 2.43 0l2.78 2.79a.18.18 0 0 0 .25 0l7.35-7.35a1.72 1.72 0 0 1 2.38 0l1.45 1.45a1.68 1.68 0 0 1 0 2.37L10.74 19.42a1.72 1.72 0 0 1-1.19.49Zm-5.8-7.23 5.67 5.67a.18.18 0 0 0 .25 0L20.1 7.93a.18.18 0 0 0 0-.25l-1.45-1.45a.19.19 0 0 0-.26 0L11 13.58a1.72 1.72 0 0 1-2.37 0l-2.74-2.79a.16.16 0 0 0-.26 0l-1.88 1.89Z" fill="#000"/></svg>
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M13.833 28.125 20 21.958l6.167 6.167 1.958-1.958L21.958 20l6.167-6.167-1.958-1.958L20 18.042l-6.167-6.167-1.958 1.958L18.042 20l-6.167 6.167ZM20 36.667q-3.417 0-6.458-1.313-3.042-1.312-5.313-3.583t-3.583-5.313Q3.333 23.417 3.333 20q0-3.458 1.313-6.5 1.312-3.042 3.583-5.292t5.313-3.562Q16.583 3.333 20 3.333q3.458 0 6.5 1.313 3.042 1.312 5.292 3.562t3.562 5.292q1.313 3.042 1.313 6.5 0 3.417-1.313 6.458-1.312 3.042-3.562 5.313T26.5 35.354q-3.042 1.313-6.5 1.313Z"/></svg>
                        }
                    </div>
                    <h4 className='processing_modal_container_title'>{props.hasError === '' ? "Your registration is successful !" : props.hasError}</h4>
                    {props.hasSuccess !== '' ?
                        // <div className='processing_modal_go_step_btn' onClick={() => props.handleClose({onlyClose: true, redirect: true})}>Go to the next step {'>'} </div>
                        renderMessage() 
                    :
                        <div className='processing_modal_go_step_btn' onClick={() => props.handleClose({onlyClose: true, redirect: false})}>Try again</div>
                    }
                </div>
            :
                <div>
                    <img src={loadingGifImg} alt="loading..."/>
                    <h4 className='processing_modal_container_title'>Processing Registration</h4>
                    <p className='processing_modal_container_content'>Your Submission has been received and we are processing your registration.
                        This process can make several minutes to complete.Do not close this window!
                    </p>
                </div>
            }
            </div>
        </div>
    )
}
export default TenDlcLoading