import * as ACTION from "../constants/dashboardActionTypes";

export const fetchDashboardCount = (payload) => ({
    type: ACTION.FETCH_DASHBOARD_COUNT,
    payload: payload
});

export const fetchSingleCount = (payload) => ({
    type: ACTION.FETCH_SINGLE_COUNT,
    payload: payload
});

export const fetchPipelineSources = (payload) => ({
    type: ACTION.FETCH_PIPELINE_SOURCES,
    payload: payload
});

export const fetchChartData = (payload) => ({
    type: ACTION.FETCH_CHART_DATA,
    payload: payload
})

export const fetchUserActivities = (payload) => ({
    type: ACTION.FETCH_USER_ACTIVITIES,
    payload: payload
})

export const fetchUserTimelines = (payload) => ({
    type: ACTION.FETCH_USER_TIMELINES,
    payload: payload
})

export const fetchUserNotifications = (payload, callback) => ({
    type: ACTION.FETCH_USER_NOTIFICATIONS,
    payload: {payload, callback}
})


export const resetNotifications = (payload) => ({
    type: ACTION.RESET_USER_NOTIFICATIONS,
    payload: payload
})