import { Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react';

const SingleSkeleton = () => {
    return (
        <Card className="stage-distribution-card">
            <div className="d-flex justify-content-between">
                <Skeleton width={50}/>
                <Skeleton width={30}/>
            </div>
            <Skeleton width={80}/>
            <Skeleton/>
        </Card>
    )
}

export const MiddleListSkeleton = () => {
    return (
        <>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
        </>
    )
}
