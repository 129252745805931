import React from "react";
import { Button, styled } from "@material-ui/core";

const BasicTabWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#F7F7F8",
  padding: 2,
  borderRadius: 12,

  display: "flex",
  alignItems: "center",
  gap: 2,

  "& .MuiButton-contained": {
    color: "#14151A",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DEE0E3",
  },
  "& .MuiButton-text": {
    color: "#0F132499",
    paddingInline: 16,
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },
}));
const TabButton = styled(Button)(({ theme }) => ({
  // fontFamily: "'Inter', sans-serif",
  fontSize: 12,
  fontWeight: 500,
  textTransform: "capitalize",
  padding: "2px 4px",
  borderRadius: 10,
}));

const CardSectionTab = ({ list, activeTab, onActiveTab, className = "" }) => {
  return (
    <BasicTabWrapper className={className}>
      {list.map((item) => (
        <TabButton
          variant={item.id === activeTab ? "contained" : "text"}
          disableElevation
          onClick={() => onActiveTab(item.id)}
          fullWidth
        >
          {item.title}
        </TabButton>
      ))}
    </BasicTabWrapper>
  );
};

export default CardSectionTab;