export const fetchInitialDashboardDataReducers = {
    fetchDashboardInitialDataStart: (state) => {
        state.loadingDashboard = true;
    },
    fetchDashboardInitialDataSuccess: (state, action) => {

        state.loadingDashboard = false;
        state.initialData = action.payload;
        if (action.payload.dashboardGoalStat) {
            state.goalList = action.payload.goalList ?? [];
            state.selectedGoal = action.payload.selectedGoal ?? null;
            state.userSaleGoalId = action.payload.dashboardGoalStat.userSaleGoalId ?? "";
            state.startedTodayAt = action.payload.dashboardGoalStat.startedTodayAt ?? 0;
            state.shouldBeAt = action.payload.dashboardGoalStat.shouldBeAt ?? 0;
            state.goalForToday = action.payload.dashboardGoalStat.goalForToday ?? 0;
            state.achievedToday = action.payload.dashboardGoalStat.achievedToday ?? 0;
            state.stillNeededForToday = action.payload.dashboardGoalStat.stillNeededForToday ?? 0;
            state.currentFrequencyTotalSale = action.payload.dashboardGoalStat.currentFrequencyTotalSale ?? 0;
            state.currentGoalPercentage = action.payload.dashboardGoalStat.currentGoalPercentage ?? 0;
            state.previousGoalTotalSale = action.payload.dashboardGoalStat.previousGoalTotalSale ?? 0;
            state.currentFrequencyPredictedSale = action.payload.dashboardGoalStat.currentFrequencyPredictedSale ?? 0;
            state.trendingForText = action.payload.dashboardGoalStat.trendingForText ?? '';
            state.currentVsLastSalesGrowth = action.payload.dashboardGoalStat.currentVsLastSalesGrowth ?? 0;
            state.currentMonth = action.payload.dashboardGoalStat.currentMonth ?? 0;
            state.currentFrequencyStartDate = action.payload.dashboardGoalStat.currentFrequencyStartDate ?? 0;
            state.currentFrequencyEndDate = action.payload.dashboardGoalStat.currentFrequencyEndDate ?? 0;
            state.currentYear = action.payload.dashboardGoalStat.currentYear ?? 0;
            state.frequency = action.payload.dashboardGoalStat.frequency;
        }

        state.totalLeadCount = action.payload.totalLeadCount ?? 0;
        state.totalActiveDealCount = action.payload.totalActiveDealCount ?? 0;
        state.totalAppointmentCount = action.payload.totalAppointmentCount ?? 0;
        state.totalUnreadMessage = action.payload.totalUnreadMessage ?? 0;
        state.totalPipelineList = action.payload.totalPipelineList ?? [];
        state.selectedPipeline = action.payload.selectedPipeline ?? "";
        state.stageInformation = action.payload.stageInformation.data ?? [];
        state.stageInformationCount = action.payload.stageInformation.totalCount ?? [];

        state.activity = action.payload.totalActivity ?? null;

    },
    fetchDashboardInitialDataFail: (state, action) => {
        state.loadingDashboard = false;
        state.error = action.payload;
    }
};