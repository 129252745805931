import React from "react";
import { Modal, Paper} from "@material-ui/core";
import Utils from "../../helpers/Utils";
import useNotificationPopUpStyle from "./useNotificationPopUpStyle";
import {ImportRedAlert} from "./Icons";
import Icons from "../dashboard/BrowserAlert/Icons";


const NotificationPopupAlert = ({ open, handleClose }) => {
  const classes = useNotificationPopUpStyle();
  return (
      <Modal
          open={open}
          onClose={() => { }}
          aria-labelledby="multi-stepper-modal-title"
          aria-describedby="multi-stepper-modal-description"
          className={`${classes.modalWrapper} global-xs-modal add_list_modal`}
          disableEnforceFocus
      >
        <Paper className={classes.modalInner}>
          <div className={classes.headerWrapper}>
            <div className={classes.headerInner}>
              <h2 className={classes.headerTitle}>Important Announcements - Action Required</h2>
              <div onClick={handleClose} className={classes.closeCircleIcon}>
                {Icons.closeCircle}
              </div>
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.bodyContainer}>
              <div className={classes.bodyTextContainer}>
                <div className={`${classes.imageContainer} ${classes.zoomInZoomOut}`}>
                  <ImportRedAlert/>
                </div>
                <div className={classes.announcement}>
                  UPDATE: We've made some adjustments to our plans to minimize disruption.
                </div>
                <div className={classes.messageTitle}>
                  Dear {Utils.getAccountData("fullName")},<br/>
                  On <span className={classes.textStrong}>May, 14 2024</span>, when you login you’ll be prompted to disconnect and reconnect your email inside your account.
                  Once you reconnect you’ll be upgraded to our new email system.
                  We will not be automatically disconnecting your email.
                </div>
                <br/>
                <div className={classes.messageTitle}>
                  On <span className={classes.textStrong}>May, 16 2024</span>, we will be launching our new <span className={classes.textStrong}>bulk emailing</span> feature.
                  We’ve been working on this new feature for the last 4 months and we’re ready to go live.
                  In order to access this feature you’ll first need to disconnect and reconnect your email.
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
  );
};

export default NotificationPopupAlert;
