import React from 'react';
import { Skeleton } from '@material-ui/lab';
const MonthlyGoalSkeleton = () => {
    
    return (
    
        <div style={{width:'100%',backgroundColor:'white',padding:'12px'}}>
        <div style={{display:'flex',justifyContent:'space-between', width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',gap:'12px'}}>
                    <Skeleton variant="rect" width={'60%'} height={20} />
                </div>
                <div style={{width:'100%',display:'flex',gap:'12px',justifyContent:'end'}}>
                    <Skeleton variant="rect"  width={80} height={20} />
                    <Skeleton variant="rect"  width={80} height={20} />
                </div>
            </div>
            <div style={{width:'100%', display: "flex",justifyContent:'space-between',marginTop:'28px' }}>
                <div style={{width:'100%',display:'flex', flexDirection:'column',gap:'18px'}}>
                    <Skeleton variant="rect" width={'100%'} height={140} style={{borderRadius:'6px'}}/>
                    <Skeleton variant="rect" width={'100%'} height={140} style={{borderRadius:'6px'}}/>
                    <Skeleton variant="rect" width={'100%'} height={140} style={{borderRadius:'6px'}}/>
                </div>
            </div>
        </div>
    
    );
};

export default MonthlyGoalSkeleton;
