import React from 'react';
import { Skeleton } from '@material-ui/lab';
const StageSkeleton = () => {
    
    return (
    
        <div style={{width:'100%',backgroundColor:'white',display:'flex',justifyContent:'space-between'}}>
            <div style={{width:'40%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Skeleton variant="circle" width={'270px'} height={'270px'} />
            </div>
            <div style={{width:'60%', display:'flex', flexDirection:'column',gap:'12px'}}>
                <Skeleton variant="rect" width={'100%'} height={90} style={{borderRadius:'6px'}} />
                <Skeleton variant="rect" width={'100%'} height={90} style={{borderRadius:'6px'}} />
                <Skeleton variant="rect" width={'100%'} height={90} style={{borderRadius:'6px'}} />
            </div>
        </div>
    
    );
};

export default StageSkeleton;
