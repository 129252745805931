import React from "react";
import { IconButton, styled } from "@material-ui/core";
import Title from "../common/Title";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EachAnnouncement from "./eachAnnouncement";

const AnnouncementsWrapper = styled("div")(({ theme }) => ({
  padding: 24,
  backgroundColor: "#ffffff",
  border: "1px solid #E9EAEC",
  borderRadius: 12,
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,

    "& .actions": {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& .userWrap": {
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
      },
    },
  },

  "& .announcementList": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
}));

const DUMMY_DATA = [
  {
    id: 1,
    title: "Trading Ethereum Derivatives",
    description: "Dear Phemex Traders, The day has finally come..",
    img: "https://images.unsplash.com/photo-1719937050445-098888c0625e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    title: "Trading Bitcoin Derivatives",
    description: "Dear Phemex Traders, The day has finally come..",
    img: "https://images.unsplash.com/photo-1728055279265-a1596deda909?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const Announcements = () => {
  return (
    <AnnouncementsWrapper>
      <div className="header">
        <Title text="Announcements" />

        <div className="actions">
          <div>
            <IconButton size="small">
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="announcementList">
        {DUMMY_DATA.map((item, index) => (
          <EachAnnouncement
            key={index}
            title={item.title}
            description={item.description}
            imageUrl={item.img}
          />
        ))}
      </div>
    </AnnouncementsWrapper>
  );
};

export default Announcements;
