import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import GerenalStep from './GerenalStep';
import BusinessStep from './BusinessStep';
import PointContact from './PointContact';
import TermPolicy from './TermPolicy';
import { getUserSettingsiDataApi, submitStandardPolicyApi } from '../../../api/dashboardApi';
import { useHistory, useLocation } from 'react-router-dom';
import './twilio-migration.css'
import { DashboardAlertPopup } from '../DashboardAlertPopup';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'unset'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['General Information', 'Business Information', 'Points of Contact',/*  'Campaign Details', */ 'Terms and Privacy Policy'];
}

const TwilioMigrationSteps = () => {
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [submitting, setSubmitting] = useState(false)
    const [isShowLoading, setIsShowLoading] = useState(false)
    const [hasError, setHasError] = useState('')
    const [hasSuccess, setHasSuccess] = useState('')

    const [isShowCampaignRegistration, setIsShowCampaignRegistration] = useState(false);

    const [isShowRegSuccess, setIsShowRegSuccess] = useState(false);

    /* for campaign reg */
    const [response, setResponse] = useState(null);

    const steps = getSteps();

    const [isLoading, setIsLoading] = useState(true);


    const search = useLocation().search;
    const policyTypeValue = new URLSearchParams(search).get('policyType')

    /* steps data */
    const [generalSteps, setGeneralSteps] = useState({
        customer_name: '',
        street: '', //address
        city: '',
        postal_code: '',
        region: '', //state
        iso_country: 'US',
        policyType: 2
    })

    useEffect(() => {
        try {
            getUserSettingsiDataApi({ 'twilio_migration_data': true ,"from": "twilio_setup"}).then(res => {
                let response = res.data;
                if (response.status === 'success') {
                    let info = response.data;
                    if(policyTypeValue){
                        setGeneralSteps((prev) => { return { ...prev, policyType: policyTypeValue } })
                    }
                    else if (history.location.state) {
                        let state_value = history.location.state;
                        if (typeof state_value === 'object') {
                            if (state_value.policyType) {
                                setGeneralSteps((prev) => { return { ...prev, policyType: state_value.policyType } })
                            }
                            else {
                                if (info.policyType) {
                                    setGeneralSteps((prev) => { return { ...prev, policyType: info.policyType } })
                                }
                            }
                        }
                        else {
                            if (info.policyType) {
                                setGeneralSteps((prev) => { return { ...prev, policyType: info.policyType } })
                            }
                        }
                    }
                    else {
                        if (info.policyType) {
                            setGeneralSteps((prev) => { return { ...prev, policyType: info.policyType } })
                        }
                        if (info.need_to_migration !== 1 && !info.updatable) {

                            history.push('/')
                            
                        }
                    }

                    setIsLoading(false)
                    
                }
                else {
                    setIsLoading(false)
                }
            })
                .catch(error => {
                    setIsLoading(false)
                })
        } catch (err) {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const [businessSteps, setBusinessSteps] = useState({
        'business_identity': '',
        'business_type': '',
        'business_industry': '',
        'business_registration_id_type': '',
        'busiess_registration_id': '',
        'website_url': '',
        'type': ''
    })

    const [pointRep1Steps, setPointRep1Steps] = useState({
        'rep_user_1_business_title': '',
        'rep_user_1_email': '',
        'rep_user_1_first_name': '',
        'rep_user_1_job_position': '',
        'rep_user_1_last_name': '',
        'rep_user_1_phone_number': '',
        'auth_rep_1': true
    })

    const [pointRep2Steps, setPointRep2Steps] = useState({
        'rep_user_2_business_title': '',
        'rep_user_2_email': '',
        'rep_user_2_first_name': '',
        'rep_user_2_job_position': '',
        'rep_user_2_last_name': '',
        'rep_user_2_phone_number': '',
        'auth_rep_2': false
    })

    /* handle submit data */
    const handleSubmitInformation = () => {
        // setIsShowLoading(true)
        // setSubmitting(true)
        // setTimeout(() => {
        //     setHasSuccess("Successfully registered.")
        //     // setIsShowLoading(false);
        //     setSubmitting(false)
        // }, 5000);
        // return
        if (submitting) {
            return;
        }
        /* check validation */
        setIsShowLoading(true)
        setSubmitting(true)
        // -------
        // if (pointRep1Steps.rep_user_1_phone_number){
        //     pointRep1Steps.rep_user_1_phone_number = pointRep1Steps.rep_user_1_phone_number.substring(1);
        // }

        // if (pointRep2Steps.rep_user_2_phone_number){
        //     pointRep2Steps.rep_user_2_phone_number = pointRep2Steps.rep_user_2_phone_number.substring(1);
        // }

        if (pointRep1Steps.rep_user_1_phone_number && pointRep1Steps.rep_user_1_phone_number.length === 11) {
            pointRep1Steps.rep_user_1_phone_number = pointRep1Steps.rep_user_1_phone_number.substring(1);
        }

        if (pointRep2Steps.rep_user_2_phone_number && pointRep2Steps.rep_user_2_phone_number.length === 11) {
            pointRep2Steps.rep_user_2_phone_number = pointRep2Steps.rep_user_2_phone_number.substring(1);
        }
        // -------
        /* handle api call */
        let formData = {
            ...generalSteps,
            ...businessSteps,
            ...pointRep1Steps,
            ...pointRep2Steps
        }
        submitStandardPolicyApi(formData).then(res => {
            let response = res.data;
            if (response.status === "success") {
                setHasSuccess("Successfully registered.")
                setResponse({
                    "campaign_setting_available": response?.campaign_setting_available || false,
                    "campaign_reg_failed_reason": response?.campaign_reg_failed_reason || ""
                })
                // history.push('/')
                // setIsShowLoading(false);
            }
            else {
                // setIsShowLoading(false);
                if(response.message){
                    setHasError(response.message)

                }else{
                    setHasError('Can not register 10DLC informaiton !')
                }
                if (window.showNotification !== undefined) {
                    window.showNotification("ERROR", "Something went wrong !");
                }
            }
            setSubmitting(false)

        }).catch((e) => {
            setSubmitting(false)
            setHasError('Can not register 10DLC informaiton !')
            if (window.showNotification !== undefined) {
                window.showNotification("ERROR", "Something went wrong !");
            }
            setSubmitting(false)

        })
    }

    const handleUpdateData = (step_name, field, value) => {
        if (step_name === 'general') {
            setGeneralSteps({
                ...generalSteps,
                [field]: value
            })
        } else if (step_name === 'business') {
            setBusinessSteps({
                ...businessSteps,
                [field]: value
            })
        } else if (step_name === 'pointRep1') {
            setPointRep1Steps({
                ...pointRep1Steps,
                [field]: value
            })
        } else if (step_name === 'pointRep2') {
            setPointRep2Steps({
                ...pointRep2Steps,
                [field]: value
            })
        }
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <GerenalStep data={generalSteps} handleUpdateData={handleUpdateData} handleStep={handleComplete} />;
            case 1:
                return <BusinessStep data={businessSteps} handleUpdateData={handleUpdateData} handleStep={handleComplete} handleBack={handleBack} />;
            case 2:
                return <PointContact data1={pointRep1Steps} data2={pointRep2Steps} handleUpdateData={handleUpdateData} handleStep={handleComplete} handleBack={handleBack} />;
            case 3:
                return <TermPolicy handleBack={handleBack} handleSubmit={handleSubmitInformation} submitting={submitting} />;
            default:
                return 'Unknown step';
        }
    }

    const isStepOptional = (step) => {
        return null;
    };

    const handleNext = () => {
        const newActiveStep = activeStep + 1
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        handleNext();
    };

    // const renderSucessfullModalFlag = () => {
    //     if(isShowLoading){
    //         return false;
    //     }
    //     else if(hasError !== "" || hasSuccess !== ""){
    //         return true
    //     }
    // }
    const handleTenDlcClose = ({ onlyClose = true, redirect = false }) => {
        if (onlyClose && redirect) {
            setIsShowLoading(false)
            setHasError('')
            setHasSuccess('')

            if (response != null) {
                if (!response.campaign_setting_available) {
                    setIsShowCampaignRegistration(true)
                    return;
                } else {
                    if (response.campaign_reg_failed_reason !== "") {
                        setIsShowCampaignRegistration(true)
                        return;
                    }
                }
            }
            history.push('/')
            return;
            /* redirect to epsecific route */
            // history.push('/messaging/services');
            // history.push('/');
        }
        else if (onlyClose) {
            setSubmitting(false)
            setHasError('')
            setHasSuccess('')
            setIsShowLoading(false)
            setActiveStep(0)
            // history.push('/')
        }
    }

    const handleCloseRegistration = () => {
        setIsShowRegSuccess(false)
        history.push('/')
    }

    /* for campaign registration */
    const handleCloseCampaignRegistration = () => {
        setIsShowCampaignRegistration(false)
        setIsShowRegSuccess(true)
        /* redirect to dashboard */
        // history.push('/');
    }

    if (isLoading) {
        return (
            <div className={classes.root} style={{
                minHeight: '200px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
            }}>
                Please wait...
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                {...buttonProps}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>

            <div>
                {getStepContent(activeStep)}
            </div>
            {(isShowLoading) &&
                <DashboardAlertPopup
                    alertFor={'twilio_migration_processing'}
                    userType={'all'}
                    handleClose={handleTenDlcClose}
                    // sucessfullModal={renderSucessfullModalFlag()}
                    hasError={hasError}
                    hasSuccess={hasSuccess}
                    response={response}
                />
            }
            {/* {(isShowLoading && (hasError === "" && hasSuccess === "")) &&   
                <TenDlcPopupApp alertFor="twilio_migration_processing" handleClose={handleTenDlcClose} />
            }
            {(isShowLoading && hasError !== "") &&   
                <TenDlcPopupApp alertFor="twilio_migration_failed" handleClose={handleTenDlcClose} />
            }
            {(isShowLoading && hasSuccess !== "") &&   
                <TenDlcPopupApp alertFor="twilio_migration_success" handleClose={handleTenDlcClose} />
            } */}

            {isShowCampaignRegistration &&
                <DashboardAlertPopup
                    alertFor={'twilio_migration_campaign_registration'}
                    userType={'all'}
                    handleClose={handleCloseCampaignRegistration}
                    data={response}
                />
            }
            {/* {(isShowCampaignRegistration) &&   
                <TenDlcPopupApp 
                    alertFor="twilio_migration_campaign_registration" 
                    handleClose={handleCloseCampaignRegistration}
                    data={response}
                />
            } */}

            {(isShowRegSuccess) &&
                <DashboardAlertPopup
                    alertFor={'campaign_registration_success'}
                    userType={'all'}
                    handleClose={handleCloseRegistration}
                />
            }
            {/* {(isShowRegSuccess) &&   
                <TenDlcPopupApp 
                    alertFor="campaign_registration_success" 
                    handleClose={handleCloseRegistration}
                    data={response}
                />
            } */}
        </div>
    );
}
export default TwilioMigrationSteps;