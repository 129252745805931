import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const getDashboardCount = async (payload) => {
    return sendRequest("post", Api.dashboardCount, payload);
}

export const getPipelineSources = async (payload) => {
    return sendRequest("post", Api.pipelineSources, payload);
}

export const getChartData = async (payload) => {
    return sendRequest("post", Api.chartData, payload);
}

export const getUserActivites = async (payload) => {
    return sendRequest("post", Api.userActivities, payload);
}

export const getUserTimelines = async (payload) => {
    return sendRequest("post", Api.userTimelines, payload);
}

export const getUserNotifications = async (payload) => {
    return sendRequest("post", Api.userNotifications, payload);
}

/* for user alert */
export const getUserAlertMessageApi = async (payload) => {
    return sendRequest("post", Api.userAlertMessage, payload);
}

export const getUserSettingsiDataApi = async (payload) => {
    return sendRequest("post", Api.userSettingData, payload);
}

export const getUserTwilioMigrationInfoApi = async (payload) => {
    return sendRequestBackEnd("post", Api.twilioMigrationInfo, payload);
}

/* for twilio term and condition api */
export const submitStandardPolicyApi = async (payload) => {
    // return sendRequestBackEnd("post", Api.submitTwilioMigrationInfo, payload);
    return sendRequest("post", Api.submitTwilioMigrationInfo, payload);
}

/* for claim contact */
export const getContactDataByIdApi = async (payload) => {
    return sendRequest("post", Api.getClaimContactDetails, payload);
}
export const ContactClaimApi = async (payload) => {
    return sendRequest("post", Api.contactClaim, payload);
}

export const getCurrentCreditApi = async (payload) => {
    return sendRequest("post", Api.currentCredit, payload);
}


/* campaign registration for twilio */
export const getUserA2pBrnadListApi = async (payload) => {
    return sendRequest("POST", Api.getA2pBrandList, payload);
}
export const getUserMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.getMessagingService, payload);
}
export const createCampaignUseCaseApi = async (payload) => {
    return sendRequest("POST", Api.createCampaignUseCase, payload);
}
export const createCampaignUseCaseV2Api = async (payload) => {
    return sendRequest("POST", Api.createCampaignUseCasev2, payload);
}
export const requestForSoleProprietorRegistrationApi = async (payload) => {
    return sendRequest("POST", Api.requestForSoleProprietorRegistration, payload);
}
export const resendSoleProprietorOtpApi = async (payload) => {
    return sendRequest("POST", Api.resendSoleProprietorOtp, payload);
}
export const checkSoleProprietorshipFeeApi = async (payload) => {
    return sendRequest("POST", Api.checkSoleProprietorshipFee, payload);
}
export const getTwilioCampaignUseCaseApi = async (payload) => {
    return sendRequest("POST", Api.getTwilioCampaignUseCase, payload);
}

export const closeA2pModalApi = async (payload) => {
    return sendRequest("post", Api.closeA2pModal, payload);
}

export const getViewControlDataApi = async (payload) => {
    return sendRequest("POST", Api.getViewControlData, payload);
}

export const checkUserA2pPopupRequiredApi = async (payload) => {
    return sendRequest("POST", Api.checkUserA2pPopupRequired, payload);
}

export const deleteUserAlertMessageApi = async (payload) => {
    return sendRequest("POST", Api.deleteUserAlertMessage, payload);
}

export const getUserAlertMessagesApi = async (payload) => {
    return sendRequest("post", Api.userAlertMessages, payload);
}