import { createSlice } from '@reduxjs/toolkit';
import {
    ACTIVITY_TYPE_CALL,
    APPOINTMENT_SCHEDULE_TODAY,
    DATE_FILTER_LABEL,
    FILTER_TYPE_TEAM,
    GOAL_TYPE_SALES
} from '../../constants/CoreConstants';
import { fetchUserCountDataReducers } from '../reducers/fetchUserCountDataReducers';
import { fetchActiveDealCountReducers } from '../reducers/fetchActiveDealCountReducers';
import { fetchAppointmentCountReducers } from '../reducers/fetchAppointmentCountReducers';
import { fetchInitialDashboardDataReducers } from '../reducers/fetchInitialDashboardDataReducers';
import { fetchActivityReducers } from "../reducers/fetchActivityReducers";
import {fetchUserStageInfoReducers} from "../reducers/fetchUserStageInfoReducers";
import {fetchUserSalesGoalReducers} from "../reducers/fetchUserSalesGoalReducers";


const initialState = {

    //Load Full Dashboard
    loadingDashboard: false,
    initialData: null,
    //Goal Data
    loadingGoalStats: false,
    goalList: [],
    selectedGoal: null,
    userSaleGoalId: "",
    goalStatFilter: GOAL_TYPE_SALES,
    startedTodayAt: 0,
    shouldBeAt: 0,
    goalForToday: 0,
    achievedToday: 0,
    stillNeededForToday: 0,
    currentFrequencyTotalSale: 0,
    currentGoalPercentage: 0,
    previousGoalTotalSale: 0,
    currentFrequencyPredictedSale: 0,
    trendingForText: '',
    currentVsLastSalesGrowth: null,
    currentMonth: null,
    currentFrequencyStartDate: null,
    currentFrequencyEndDate: null,
    currentYear: null,
    frequency: null,

    // User Sales Goals Block
    loadingUserSalesGoalData: false,

    //Lead Count Block
    loadingLeadCount: false,
    totalLeadCountFilterType: FILTER_TYPE_TEAM,
    totalLeadCountStartDate: null,
    totalLeadCountEndDate: null,
    totalLeadCount: null,

    //Deal Count Block
    loadingActiveDealCount: false,
    totalActiveDealCountFilterType: FILTER_TYPE_TEAM,
    totalActiveDealStartDate: null,
    totalActiveDealEndDate: null,
    totalActiveDealCount: null,

    //Appointment Count Block
    loadingAppointmentCount: false,
    totalAppointmentCountFilterType: FILTER_TYPE_TEAM,
    appointmentSchedule: APPOINTMENT_SCHEDULE_TODAY,
    totalAppointmentCount: null,

    //Unread message Block
    totalUnreadMessage: null,

    //Pipeline Stage Block
    loadingUserStageInfoData: false,
    totalPipelineList: [],
    stageInformation: [],
    stageInformationCount: 0,
    selectedPipeline: "",
    userStageInfoFilterType: FILTER_TYPE_TEAM,
    userStageInfoStartDate: null,
    userStageInfoEndDate: null,

    //Activity Data
    loadingActivityData: false,
    selectedActivity: ACTIVITY_TYPE_CALL,

    activityFilterType: FILTER_TYPE_TEAM,
    activityStartDate: null,
    activityEndDate: null,
    activity: null,

    error: null,
};

const insuranceDashboardSlice = createSlice({
    name: 'insuranceDashboard',
    initialState,
    reducers: {
        //For initial Data
        ...fetchInitialDashboardDataReducers,

        //For only Lead Count
        ...fetchUserCountDataReducers,

        //For Active Deal Count
        ...fetchActiveDealCountReducers,

        //For only Appointment Count
        ...fetchAppointmentCountReducers,

        //For only Activity Count
        ...fetchActivityReducers,

        //Pipeline Stage Block
        ...fetchUserStageInfoReducers,

        //User Sales Goal
        ...fetchUserSalesGoalReducers
    },
});


// Export actions
export const {
    fetchDashboardInitialDataStart,
    fetchDashboardInitialDataSuccess,
    fetchDashboardInitialDataFail,
    fetchLeadCountStart,
    fetchLeadCountSuccess,
    fetchLeadCountFail,
    fetchActiveDealCountStart,
    fetchActiveDealCountSuccess,
    fetchActiveDealCountFail,
    fetchAppointmentCountStart,
    fetchAppointmentCountSuccess,
    fetchAppointmentCountFail,
    fetchActivityStart,
    fetchActivitySuccess,
    fetchActivityFail,
    fetchUserStageInfoStart,
    fetchUserStageInfoSuccess,
    fetchUserStageInfoFail,
    fetchUserSalesGoalStart,
    fetchUserSalesGoalSuccess,
    fetchUserSalesGoalFail
} = insuranceDashboardSlice.actions;

// Export reducer
export default insuranceDashboardSlice.reducer;
