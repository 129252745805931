import moment from "moment";

const CoreConstants = {

}

export default CoreConstants;

export const DROPDOWN_BOTTOM_SETUP = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    getContentAnchorEl: null,
}

export const DATEPICKER_INITIAL_SETTING = {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    ranges: {
        'All': [
            moment('09/01/2020'), moment()
        ],
        'Last 30 Days': [
            moment().subtract(29, 'days'), moment()
        ],
        'Last 7 Days': [
            moment().subtract(6, 'days'), moment()
        ],
        'Last Month': [
            moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')
        ],
        'This Month': [
            moment().startOf('month'), moment().endOf('month')
        ],
        Today: [
            moment(), moment()
        ],
        Yesterday: [
            moment().subtract(1, 'days'), moment().subtract(1, 'days')
        ]
    }
}

export const FUNNEL_SETUP = {
    chart: {
        type: 'funnel'
    },
    title: {
        text: null
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b> ({point.y:,.0f})',
                softConnector: true
            },
            center: ['40%', '50%'],
            neckWidth: '30%',
            neckHeight: '25%',
            width: '80%'
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Total',
        data: [],
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
}

export const ACTIVITY_TYPE = {
    PAST: 1,
    PRESENT: 2,
    FUTURE: 3
}

export const TWILIO_SETUP = {
    policyTypeLowVolumn: 1,
    policyTypeHighVolumn: 2,
}

export const emailProvider = {
    DEFAULT: 0,
    NYLAS_V1: 1,
    NYLAS_V3: 2
}

export const connectedEmailStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    FAILED: "FAILED",
    TRASH: "TRASH"
};

export const NYLAS_V3_AGENCIES = [1, 125, 53, 82, 57, 106, 97, 16, 30, 62, 40, 87, 92, 103, 129, 38, 67, 85, 159, 193, 157, 45, 59, 190, 102, 145, 51, 201, 225, 73, 173, 42, 32, 150, 124, 44, 234, 226, 215, 214, 213, 46, 141, 218, 216, 219];