import React, { createContext } from "react";
import './App.css';
import Routes from "./routes/Routes";
import {BrowserRouter as Router} from 'react-router-dom';
import { createBrowserHistory } from "history";
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Utils from "./helpers/Utils";
import ErrorBoundary from "./components/common/ErrorBoundary";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

function App({ history = defaultHistory }) {

    const generateClassName = createGenerateClassName({
        productionPrefix: 'dshb_' + Utils.generateRandomString(5),
    });

    return (
        <HistoryProvider.Provider value={history}>
            <ErrorBoundary>
                <StylesProvider generateClassName={generateClassName}>
                    <Router history={history}>

                        <Routes history={history}/>
                    </Router>
                </StylesProvider>
            </ErrorBoundary>
        </HistoryProvider.Provider>
    );
}

export default App;
