//Dashboard Actions
export const FETCH_DASHBOARD_COUNT = 'FETCH_DASHBOARD_COUNT';
export const STORE_DASHBOARD_COUNT = 'STORE_DASHBOARD_COUNT';
export const FETCH_SINGLE_COUNT = 'FETCH_SINGLE_COUNT';
export const STORE_DEAL_COUNT = 'STORE_DEAL_COUNT';
export const STORE_DEAL_VALUE = 'STORE_DEAL_VALUE';
export const STORE_DEAL_CONVERSION = 'STORE_DEAL_CONVERSION';
export const FETCH_PIPELINE_SOURCES = 'FETCH_PIPELINE_SOURCES';
export const STORE_PIPELINE_SOURCES = 'STORE_PIPELINE_SOURCES';
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA';
export const STORE_FUNNEL_PIPELINE_ID = 'STORE_FUNNEL_PIPELINE_ID';
export const STORE_FUNNEL_DATA = 'STORE_FUNNEL_DATA';
export const STORE_PIE_PIPELINE_ID = 'STORE_PIE_PIPELINE_ID';
export const STORE_PIE_DATA = 'STORE_PIE_DATA';
export const STORE_DASHBOARD_FILTERS = 'STORE_DASHBOARD_FILTERS';


//Right side panel
export const SET_RIGHT_SIDE_LOADER = 'SET_RIGHT_SIDE_LOADER';
export const FETCH_USER_ACTIVITIES = 'FETCH_USER_ACTIVITIES';
export const STORE_USER_ACTIVITIES = 'STORE_USER_ACTIVITIES';
export const FETCH_USER_TIMELINES = 'FETCH_USER_TIMELINES';
export const STORE_USER_TIMELINES = 'STORE_USER_TIMELINES';
export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';
export const STORE_USER_NOTIFICATIONS = 'STORE_USER_NOTIFICATIONS';
export const RESET_USER_NOTIFICATIONS = 'RESET_USER_NOTIFICATIONS';