import React from "react";
import Styles from "./SoleRestriction.module.css";

const SoleRestriction = ({ handleBack, information }) => {
    return(
        <div className={Styles.restrict_alert_wrapper}>
            <div className={Styles.restrict_alert_container}>
                <div className={Styles.restrict_alert_container_v2_update}>
                    <h4 className={Styles.restrict_alert_container_v2_title}>Your are not eligible for <br/> <span className={Styles.highlightRed}>SOLE PROPRIETORSHIP A2P REGISTRATION</span> </h4>
                    <p className={Styles.restrict_alert_container_v2_description}>
                        As you have more than one virtual number (<b>you have {information.count} active virtual number</b>),
                        You are not allowed to use SOLE PROPRIETORSHIP A2P REGISTRATION.
                        If you want to go with SOLE PROPRIETORSHIP A2P REGISTRATION you have to remove extra virtual numbers,
                        otherwise you have to complete regular <b>A2P STANDARD REGISTRATION</b>.
                    </p>
                </div>
                <div className={Styles.restrict_alert_go_back_btn} onClick={handleBack}>Go Back</div>
                <a href="/user/phone-settings" className={Styles.restrict_alert_go_step_btn}>Go To Virtual Number Page</a>
            </div>
        </div>
    );
};

export default SoleRestriction;