import React from "react";
import {Grid} from "@material-ui/core";
import {DealIcon} from "../common/Icons";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import {EachActivityWrapper} from "./index";

const Deal = ({ activity }) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EachActivityWrapper bgColor={"#F7F1FF"}>
                    <div className="goalIcon">
                        <DealIcon />
                    </div>
                    <div className="GoalText">
                        <Title text={activity?.totalClosedDeals ?? 0} />
                        <SubTitle text={"Total Closed Deals"} className="subTitle" />
                    </div>
                </EachActivityWrapper>
            </Grid>
        </Grid>
    );
};

export default Deal;