import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserActivities } from "../../../actions/dashboardAction";
import { HistoryProvider } from "../../../App";
import { ACTIVITY_TYPE } from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import RightListSkeleton from "../skeletons/RightListSkeleton";

const ActivityPanel = () => {

    const dispatch = useDispatch();
    const history = useContext(HistoryProvider);
    const { userActivities, rightListLoading } = useSelector(state => state.dashboardReducer);
    const [activityType,setActivityType] = useState(ACTIVITY_TYPE.PRESENT);

    useEffect(() => {
        dispatch(fetchUserActivities({
            activity_type : activityType
        }))
    },[activityType]); // eslint-disable-line react-hooks/exhaustive-deps

    const activityCallback = () => {
        dispatch(fetchUserActivities({
            activity_type : activityType
        }))
    }

    const openActivity = (e,activity) => {
        e.preventDefault();
        window.openGlobalActivityModal({
            contactDetails:activity.contact,
            editData:activity,
            callBack: activityCallback,
            from: 'dashboard'
        })
    }

    const renderActivities = () => {

        if(rightListLoading)
            return <RightListSkeleton/>

        else if(userActivities.length === 0) {
            return <p className="d-flex justify-content-center">NO ACTIVITIES</p>
        }
            

        return userActivities.map((activity,index) => {

            const fullName = activity.contact ? activity.contact.first_name + ' ' + activity.contact.last_name : '';


            return <ListItem key={index} className="activity__singleItem">
                <ListItemAvatar>
                    <Avatar>
                        {Utils.getInitial(fullName)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    className="db__activity_panel truncate"
                    primary={<>                                 
                        <Tooltip title={activity.task_title} placement="top-start">
                            <a href="#!" className="inbox__panel_message" onClick={e => openActivity(e,activity)}>{Utils.getContactDisplayName(activity.task_title)}</a>
                        </Tooltip>
                    </>}
                    secondary={activity.contact ? <a 
                        href={`/contacts/${activity.contact.id}`} 
                        className="inbox__panel_message" 
                        onClick={e => {e.preventDefault();history.push(`/contacts/${activity.contact.id}`)}}>{'With '+ Utils.getContactDisplayName(fullName,activity.contact.number,activity.contact.email)}</a> : ''}
                />
                <span className="activity__hours">
                    <time dateTime="1592960400000">
                        {
                            window.globalTimezoneConversionToDifferentTimezone(activity.date + " " + activity.time,"UTC",'','ddd, LT')
                        }
                    </time>
                </span>
            </ListItem>
        }) 
    }

    const gotoCalendar = (e) => {
        e.preventDefault();
        history.push('/activity-calendar');
    }

    return ( 
        <>
            <div className="sidebar__tab_topbar d-flex justify-content-between">
                <div className="sidebar__tab_btns_group d-flex align-items-center">
                    <Button className={`${activityType === ACTIVITY_TYPE.PAST ? 'active' : ''}`} onClick={() => setActivityType(ACTIVITY_TYPE.PAST)}>Past</Button>
                    <Button className={`${activityType === ACTIVITY_TYPE.PRESENT ? 'active' : ''}`} onClick={() => setActivityType(ACTIVITY_TYPE.PRESENT)}>Today</Button>
                    <Button className={`${activityType === ACTIVITY_TYPE.FUTURE ? 'active' : ''}`} onClick={() => setActivityType(ACTIVITY_TYPE.FUTURE)}>Future</Button>
                </div>
                <a href="/activity-calendar" onClick={gotoCalendar}>See All</a>
            </div>

            <div className="activity__tabContent awesome__scroll_bar">
                <List>
                    {renderActivities()}
                </List>
            </div>
        </>
     );
}
 
export default ActivityPanel;