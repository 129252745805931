import { Link, styled } from "@material-ui/core";
import React from "react";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";

const ItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,

  padding: 12,
  backgroundColor: "#FFFFFF",
  border: "1px solid #F4F6FB",
  borderRadius: 12,

  "& .imageWrapper": {
    height: 72,
    width: 84,
    "& .image": {
      height: "100%",
      width: "100%",
      borderRadius: 10,
      objectFit: "cover",
    },
  },

  "& .textWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .title": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "16px",
    },

    "& .subtitle": {},
    "& .learnMore": {
      // fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 12,
      color: "#3E50F7",
    },
  },
}));

const EachAnnouncement = ({ title, description, imageUrl }) => {
  return (
    <ItemWrapper>
      <div className="imageWrapper">
        <img src={imageUrl} alt="announcement" className="image" />
      </div>
      <div className="textWrapper">
        <Title text={title} className="title" />
        <SubTitle text={description} className="subtitle" />

        <Link href="#" className="learnMore">
          Learn more
        </Link>
      </div>
    </ItemWrapper>
  );
};

export default EachAnnouncement;
